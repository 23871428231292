var blue = "#0074d9";
var teal = "#26bdac";
var green = "#49c94e";
var yellow = "#f8c74a";
var orange = "#fa9146";
var red = "#d82a2a";
var pink = "#f56390";
var purple = "#65318a";
var darkBlue = "#1e689e";
export var avatarColours = {
  a: blue,
  b: teal,
  c: green,
  d: yellow,
  e: orange,
  f: red,
  g: pink,
  h: purple,
  i: darkBlue,
  j: blue,
  k: teal,
  l: green,
  m: yellow,
  n: orange,
  o: red,
  p: pink,
  q: darkBlue,
  r: purple,
  s: blue,
  t: teal,
  u: green,
  v: yellow,
  w: orange,
  x: red,
  y: pink,
  z: purple
};