import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { Box, Flex, Label, Select } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var LabelWrap = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  pointer-events: none;\n"])));
export var QuantitySelector = function QuantitySelector(_ref) {
  var purchasableQuantity = _ref.purchasableQuantity,
      setQuantity = _ref.setQuantity;
  var quantities = Array.from({
    length: purchasableQuantity
  }, function (_x, index) {
    return {
      name: "".concat(index + 1),
      value: "".concat(index + 1)
    };
  });
  return ___EmotionJSX(Box, {
    position: "relative"
  }, ___EmotionJSX(LabelWrap, {
    alignItems: "center",
    position: "absolute",
    left: "20px",
    top: "0px",
    bottom: "0px",
    zIndex: 1
  }, ___EmotionJSX(Label, {
    htmlFor: "quantity-selector",
    width: "auto",
    mb: "0px"
  }, "Quantity")), ___EmotionJSX(Select, {
    id: "quantity-selector",
    containerProps: {
      width: "100%",
      fontWeight: "light"
    },
    color: "typography.text",
    pl: "96px",
    onChange: function onChange(event) {
      return setQuantity(Number(event.currentTarget.value));
    }
  }, quantities.map(function (quantity) {
    return ___EmotionJSX("option", {
      value: quantity.value,
      key: quantity.value
    }, quantity.name);
  })));
};