import _slicedToArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React, { useCallback, useEffect, useState } from "react";
import { emitChristmasDeliveryWidget } from "@catchoftheday/analytics";
import { Flex, Heading, IconToolTip, Image, Input, LoadingSpinner, Text, TextButton, Tooltip } from "@catchoftheday/cg-components";
import { localStorage } from "@catchoftheday/utilities";
import { useChristmasDeliveryLazyQuery } from "@product-app/generated/graphql";
import { jsx as ___EmotionJSX } from "@emotion/react";
var defaultHeading = "Will you get this by Christmas?";
export var ChristmasDeliveryWidget = function ChristmasDeliveryWidget(_ref) {
  var offerId = _ref.offerId;

  var _useState = useState(false),
      showInput = _useState[0],
      setShowInput = _useState[1];

  var _useState2 = useState(),
      postcode = _useState2[0],
      setPostcode = _useState2[1];

  var _useState3 = useState(defaultHeading),
      message = _useState3[0],
      setMessage = _useState3[1];

  var _useState4 = useState(),
      errorMessage = _useState4[0],
      setErrorMessage = _useState4[1];

  var _useState5 = useState({
    show: false,
    message: ""
  }),
      tooltip = _useState5[0],
      setTooltip = _useState5[1];

  var _useChristmasDelivery = useChristmasDeliveryLazyQuery({
    onCompleted: function onCompleted(data) {
      return handleResponse(data);
    },
    onError: function onError() {
      setMessage("Sorry, we are unable to give a delivery estimate at this time.");
    }
  }),
      _useChristmasDelivery2 = _slicedToArray(_useChristmasDelivery, 2),
      getChristmasDelivery = _useChristmasDelivery2[0],
      loading = _useChristmasDelivery2[1].loading;

  var handleResponse = function handleResponse(data) {
    var _data$christmasDelive, _data$christmasDelive3;

    if ((data === null || data === void 0 ? void 0 : (_data$christmasDelive = data.christmasDelivery) === null || _data$christmasDelive === void 0 ? void 0 : _data$christmasDelive.__typename) === "ChristmasDeliveryData") {
      var _data$christmasDelive2 = data.christmasDelivery,
          _postcode = _data$christmasDelive2.postcode,
          deliveryMessage = _data$christmasDelive2.deliveryMessage,
          _tooltip = _data$christmasDelive2.tooltip,
          showTooltip = _data$christmasDelive2.showTooltip,
          tracking = _data$christmasDelive2.tracking;

      var _ref2 = tracking || {},
          category = _ref2.category,
          action = _ref2.action,
          label = _ref2.label,
          property = _ref2.property;

      localStorage.setItem("christmasDeliveryPostcode", "".concat(_postcode));
      setPostcode("".concat(_postcode));
      setShowInput(false);
      setMessage(deliveryMessage);
      setTooltip({
        show: showTooltip,
        message: _tooltip
      });
      setErrorMessage(undefined);
      emitChristmasDeliveryWidget({
        category: category,
        action: action,
        label: label,
        property: property
      });
    } else if ((data === null || data === void 0 ? void 0 : (_data$christmasDelive3 = data.christmasDelivery) === null || _data$christmasDelive3 === void 0 ? void 0 : _data$christmasDelive3.__typename) === "UserError") {
      setMessage(defaultHeading);
      setErrorMessage(data.christmasDelivery.message);
      localStorage.removeItem("christmasDeliveryPostcode");
    }
  };

  var formSubmit = function formSubmit(e) {
    e.preventDefault();

    if (postcode) {
      submitChristmasDelivery(postcode);
    }
  };

  var submitChristmasDelivery = useCallback(function (postcode) {
    getChristmasDelivery({
      variables: {
        postcode: postcode,
        offerId: offerId
      }
    });
  }, [getChristmasDelivery, offerId]);
  useEffect(function () {
    var storedPostcode = localStorage.getItem("christmasDeliveryPostcode");

    if (storedPostcode) {
      setPostcode(storedPostcode);
      submitChristmasDelivery(storedPostcode);
    }
  }, [submitChristmasDelivery]);
  return ___EmotionJSX(Flex, {
    border: "solid 1px",
    borderColor: "ui.borderColor",
    backgroundColor: "ui.backgroundColor",
    py: "15px",
    px: "30px",
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "4px"
  }, ___EmotionJSX(Image, {
    src: "https://s.catch.com.au/assets/0001/1028/634ce834a6ddc558327428.png",
    alt: "Christmas Delivery",
    width: "37px",
    height: "37px"
  }), loading ? ___EmotionJSX(LoadingSpinner, {
    mt: "35px",
    mb: "25px",
    width: "30px",
    height: "30px",
    ringSize: "3px",
    ringBg: "white"
  }) : ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Heading.h4, {
    fontWeight: "normal",
    fontSize: "base",
    my: "5px"
  }, message, tooltip.show && ___EmotionJSX(Tooltip, {
    ml: "5px",
    reference: ___EmotionJSX(IconToolTip, {
      width: "15px",
      height: "15px",
      mb: "3px"
    }),
    placement: "top"
  }, ___EmotionJSX(Text, {
    m: "0",
    fontSize: "xs"
  }, tooltip.message))), !showInput && postcode && !errorMessage && ___EmotionJSX(Text, {
    m: "0"
  }, "Delivering to ", postcode, " ", ___EmotionJSX(TextButton, {
    ml: "3px",
    onClick: function onClick() {
      return setShowInput(true);
    }
  }, "Change")), !showInput && !postcode && ___EmotionJSX(TextButton, {
    onClick: function onClick() {
      return setShowInput(true);
    }
  }, "Add your postcode"), showInput && ___EmotionJSX("form", {
    onSubmit: formSubmit,
    "aria-label": "form"
  }, ___EmotionJSX(Flex, {
    alignContent: "center",
    justifyContent: "center"
  }, ___EmotionJSX(Input, {
    p: "3",
    placeholder: "eg. 3000",
    maxWidth: "130px",
    maxLength: 4,
    name: "postcode",
    error: !!errorMessage,
    display: "inline-block",
    onChange: function onChange(e) {
      return setPostcode(e.target.value);
    }
  }), ___EmotionJSX(TextButton, {
    type: "submit",
    px: "15px"
  }, "Submit"))), errorMessage && ___EmotionJSX(Text, {
    color: "error",
    mb: "0",
    lineHeight: 1
  }, errorMessage)));
};