import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["personalisedFields"];
import React from "react";
import { Box, Form, FormError, FormInput, FormSelect, FormTextarea, Label, Span } from "@catchoftheday/cg-components";
import { BuyBoxButtons } from "../BuyBoxButtons";
import { jsx as ___EmotionJSX } from "@emotion/react";

var PersonalisedLabel = function PersonalisedLabel(_ref) {
  var label = _ref.label,
      required = _ref.required,
      name = _ref.name;
  return ___EmotionJSX(Label, {
    m: "0",
    mb: "5px",
    htmlFor: name
  }, label, required && ___EmotionJSX(Span, {
    color: "error",
    pl: "4px"
  }, "*"));
};

var renderPersonalisedField = function renderPersonalisedField(_ref2) {
  var label = _ref2.label,
      name = _ref2.name,
      elementType = _ref2.elementType,
      attributes = _ref2.attributes;

  switch (elementType) {
    case "TEXT":
      {
        var attrs = attributes;
        return ___EmotionJSX(Box, {
          mb: "20px",
          key: name
        }, ___EmotionJSX(PersonalisedLabel, {
          name: name,
          label: label,
          required: attrs.required
        }), ___EmotionJSX(FormInput, {
          id: name,
          type: "text",
          name: name,
          required: attrs.required,
          register: {
            required: {
              value: attrs.required,
              message: "".concat(label, " is required")
            },
            minLength: attrs.minlength ? {
              value: attrs.minlength,
              message: "Minimum length of ".concat(attrs.minlength)
            } : undefined,
            maxLength: attrs.maxlength ? {
              value: attrs.maxlength,
              message: "Maximum length of ".concat(attrs.maxlength)
            } : undefined
          }
        }), ___EmotionJSX(FormError, {
          name: name
        }));
      }

    case "TEXTAREA":
      {
        var _attrs = attributes;
        return ___EmotionJSX(Box, {
          mb: "20px",
          key: name
        }, ___EmotionJSX(PersonalisedLabel, {
          name: name,
          label: label,
          required: _attrs.required
        }), ___EmotionJSX(FormTextarea, {
          id: name,
          name: name,
          required: _attrs.required,
          register: {
            required: {
              value: _attrs.required,
              message: "".concat(label, " is required")
            },
            minLength: _attrs.minlength ? {
              value: _attrs.minlength,
              message: "Minimum length of ".concat(_attrs.minlength)
            } : undefined,
            maxLength: _attrs.maxlength ? {
              value: _attrs.maxlength,
              message: "Maximum length of ".concat(_attrs.maxlength)
            } : undefined
          },
          height: "100px"
        }), ___EmotionJSX(FormError, {
          name: name
        }));
      }

    case "NUMBER":
      {
        var _attrs2 = attributes;
        return ___EmotionJSX(Box, {
          mb: "20px",
          key: name
        }, ___EmotionJSX(PersonalisedLabel, {
          name: name,
          label: label,
          required: _attrs2.required
        }), ___EmotionJSX(FormInput, {
          id: name,
          type: "number",
          name: name,
          width: "100%",
          required: _attrs2.required,
          register: {
            required: {
              value: _attrs2.required,
              message: "".concat(label, " is required")
            },
            min: _attrs2.min ? {
              value: _attrs2.min,
              message: "".concat(label, " must have a minimum value of ").concat(_attrs2.min)
            } : undefined,
            max: _attrs2.max ? {
              value: _attrs2.max,
              message: "".concat(label, " must have a maximum value of ").concat(_attrs2.max)
            } : undefined
          }
        }), ___EmotionJSX(FormError, {
          name: name
        }));
      }

    case "EMAIL":
      {
        var _attrs3 = attributes;
        return ___EmotionJSX(Box, {
          mb: "20px",
          key: name
        }, ___EmotionJSX(PersonalisedLabel, {
          name: name,
          label: label,
          required: _attrs3.required
        }), ___EmotionJSX(FormInput, {
          id: name,
          name: name,
          type: "email",
          required: _attrs3.required,
          register: {
            required: {
              value: _attrs3.required,
              message: "".concat(label, " is required")
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address"
            }
          }
        }), ___EmotionJSX(FormError, {
          name: name
        }));
      }

    case "SELECT":
      {
        var _attrs4 = attributes;
        return ___EmotionJSX(Box, {
          mb: "20px",
          key: name
        }, ___EmotionJSX(PersonalisedLabel, {
          name: name,
          label: label,
          required: _attrs4.required
        }), ___EmotionJSX(FormSelect, {
          id: name,
          name: name,
          required: _attrs4.required,
          register: {
            required: {
              value: _attrs4.required,
              message: "".concat(label, " is required")
            }
          },
          containerProps: {
            width: "100%"
          },
          color: "typography.text",
          pl: "20px"
        }, _attrs4.optionAttributes.map(function (option, index) {
          return ___EmotionJSX("option", {
            key: "".concat(option.name, "-").concat(index)
          }, option.label);
        })), ___EmotionJSX(FormError, {
          name: name
        }));
      }

    default:
      return null;
  }
};

export var PersonalisedFields = function PersonalisedFields(_ref3) {
  var personalisedFields = _ref3.personalisedFields,
      addToCartProps = _objectWithoutProperties(_ref3, _excluded);

  return ___EmotionJSX(Box, {
    my: "12px"
  }, ___EmotionJSX(Form, {
    noValidate: true
  }, function (_ref4) {
    var trigger = _ref4.trigger,
        getValues = _ref4.getValues;
    return ___EmotionJSX(React.Fragment, null, personalisedFields.filter(function (field) {
      return field.name !== "";
    }).map(function (field) {
      return renderPersonalisedField(field);
    }), ___EmotionJSX(BuyBoxButtons, _extends({}, addToCartProps, {
      type: "submit",
      isFormValid: trigger,
      getValues: getValues
    })));
  }));
};