import React from "react";
import { Box, Flex, LoadingSpinner, TextButton } from "@catchoftheday/cg-components";
import { ReviewItem } from "./ReviewItem";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ReviewList = function ReviewList(_ref) {
  var reviews = _ref.reviews,
      reviewCount = _ref.reviewCount,
      onShowMore = _ref.onShowMore,
      loading = _ref.loading;
  var shouldShowMore = !loading && reviews && reviews.length > 0 && reviewCount > 5 && reviews.length < reviewCount;
  return ___EmotionJSX(Box, null, reviews && reviews.length > 0 && reviews.map(function (review, index) {
    return ___EmotionJSX(Box, {
      key: index,
      borderBottom: "1px solid",
      borderBottomColor: "ui.borderColor",
      py: "20px"
    }, ___EmotionJSX(ReviewItem, review));
  }), loading && ___EmotionJSX(Flex, {
    justifyContent: "center",
    alignItems: "center",
    py: "20px"
  }, ___EmotionJSX(LoadingSpinner, null)), shouldShowMore && ___EmotionJSX(Flex, {
    justifyContent: "center",
    py: "20px"
  }, ___EmotionJSX(TextButton, {
    onClick: onShowMore
  }, "Show more...")));
};