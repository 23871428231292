import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "isTablet"];
import React, { useRef } from "react";
import { Box } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var BuyBoxContainer = function BuyBoxContainer(_ref) {
  var children = _ref.children,
      isTablet = _ref.isTablet,
      boxProps = _objectWithoutProperties(_ref, _excluded);

  var buyBoxRef = useRef(null);
  return ___EmotionJSX(Box, _extends({
    border: isTablet ? "none" : "solid 1px",
    borderColor: "ui.borderColor",
    p: {
      xs: "0",
      md: "16px"
    },
    backgroundColor: isTablet ? undefined : "white",
    mt: {
      xs: "16px",
      lg: "0px"
    },
    mb: "16px",
    position: "relative"
  }, boxProps, {
    ref: buyBoxRef
  }), children);
};