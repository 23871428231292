export var GENERIC_ERROR_MESSAGE = "There was a problem loading this section.";
export var SOLD_OUT_MESSAGE = "This product has SOLD OUT!";
export var RECOMMENDER_SOURCE_ID = "Product page Recommendation";
export var CATCH_SELLER_ID = "1"; // Products that are not deliverable

export var DigitalProductIds;

(function (DigitalProductIds) {
  DigitalProductIds["ONEPASS_YEARLY_MEMBERSHIP"] = "1";
  DigitalProductIds["GIFT_CARD"] = "2";
  DigitalProductIds["ROUND_UP_PRODUCT"] = "3";
  DigitalProductIds["ONEPASS_MONTHLY_MEMBERSHIP"] = "4";
})(DigitalProductIds || (DigitalProductIds = {}));

export * from "./avatarColours";