import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { Box, Flex, IconCatchCLogo, IconToolTip, Link, Span, Text, Tooltip } from "@catchoftheday/cg-components";
import { TemplatedMessage } from "@catchoftheday/common-vendors";
import { styled } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var ZeroMarginOnLastChildBox = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  & > *:last-child {\n    margin-bottom: 0;\n  }\n"])));
export var ShippingInformation = function ShippingInformation(_ref) {
  var _ref$shipping = _ref.shipping,
      soldByCatch = _ref$shipping.soldByCatch,
      dropshipDisclaimer = _ref$shipping.dropshipDisclaimer,
      onePassDisclaimer = _ref$shipping.onePassDisclaimer,
      freeShippingDisclaimer = _ref$shipping.freeShippingDisclaimer,
      isFBC = _ref$shipping.isFBC,
      _ref$seller = _ref.seller,
      name = _ref$seller.name,
      slug = _ref$seller.slug,
      purchaseLimit = _ref.purchaseLimit,
      offerId = _ref.offerId,
      freeShipping = _ref.freeShipping,
      isGiftCard = _ref.isGiftCard;
  return ___EmotionJSX(Flex, null, ___EmotionJSX(Box, {
    width: !soldByCatch ? "auto" : "50px",
    flex: "0 1 auto"
  }, soldByCatch && ___EmotionJSX(IconCatchCLogo, {
    width: "37px",
    height: "auto",
    title: "Catch logo"
  })), ___EmotionJSX(Box, {
    flex: "1"
  }, freeShipping && !isGiftCard && ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Span, {
    color: "primary",
    fontWeight: "400",
    textTransform: "uppercase",
    mr: "5px"
  }, "Free Delivery"), freeShippingDisclaimer && ___EmotionJSX(Tooltip, {
    placement: "top",
    reference: ___EmotionJSX(IconToolTip, {
      width: "14px",
      height: "14px",
      mt: "-4px",
      color: "typography.subText"
    }),
    mb: !soldByCatch ? "10px" : null
  }, ___EmotionJSX(Text, {
    m: "0",
    fontSize: "xs"
  }, freeShippingDisclaimer))), ___EmotionJSX(Flex, {
    justifyContent: "space-between",
    alignItems: "flex-start"
  }, ___EmotionJSX(ZeroMarginOnLastChildBox, null, !soldByCatch && ___EmotionJSX(Span, {
    display: "inline-block",
    backgroundColor: "primary",
    color: "textWhite",
    fontSize: "xs",
    lineHeight: "12px",
    fontWeight: "medium",
    p: "4px 6px",
    mb: "5px"
  }, "Marketplace Seller"), !isGiftCard && ___EmotionJSX(Text, {
    m: 0,
    "data-testid": "sold-by"
  }, "Sold", isFBC ? "" : " and delivered", " by", " ", slug ? ___EmotionJSX(Link, {
    href: "/seller/".concat(slug, "?oid=").concat(offerId, "#popular_products")
  }, name) : ___EmotionJSX(Span, {
    fontWeight: "400"
  }, name), isFBC && ". Delivered by Catch"), isGiftCard && ___EmotionJSX(Text, {
    mt: 0
  }, "The gift card will be sent to the recipient's email shortly after purchase!"), purchaseLimit && purchaseLimit > 0 && ___EmotionJSX(Text, {
    mt: 0
  }, "Limit per customer: ", purchaseLimit), dropshipDisclaimer && !isGiftCard && ___EmotionJSX(Text, {
    mt: "0"
  }, ___EmotionJSX(TemplatedMessage, {
    templateString: dropshipDisclaimer
  })), onePassDisclaimer && ___EmotionJSX(Text, {
    mt: "0"
  }, ___EmotionJSX(Span, {
    fontWeight: "400"
  }, "Please note:"), " ", ___EmotionJSX(TemplatedMessage, {
    templateString: onePassDisclaimer
  }))))));
};