import React, { useEffect, useState } from "react";
import { AddToCartButton, BuyNowButton } from "@catchoftheday/cart-components";
import { Flex, LoadingSpinner, Span } from "@catchoftheday/cg-components";
import { queryString } from "@catchoftheday/utilities";
import { WishlistButton } from "../WishlistButton";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var BuyBoxButtons = function BuyBoxButtons(_ref) {
  var chosenQuantity = _ref.chosenQuantity,
      offerId = _ref.offerId,
      title = _ref.title,
      imageUrl = _ref.imageUrl,
      sourceId = _ref.sourceId,
      sourceType = _ref.sourceType,
      sourcePosition = _ref.sourcePosition,
      eventId = _ref.eventId,
      isFormValid = _ref.isFormValid,
      getValues = _ref.getValues,
      onWishlistClick = _ref.onWishlistClick,
      inWishlist = _ref.inWishlist,
      type = _ref.type;

  var _useState = useState(false),
      isAddToCartLoading = _useState[0],
      setIsAddToCartLoading = _useState[1];

  var _useState2 = useState(false),
      isBuyNowLoading = _useState2[0],
      setIsBuyNowLoading = _useState2[1];

  var queryParams = true ? queryString.parse(window.location.search) : null;
  var searchTrackingToken = typeof (queryParams === null || queryParams === void 0 ? void 0 : queryParams.srtrev) === "string" ? queryParams.srtrev : undefined;
  useEffect(function () {
    function forceRefresh(event) {
      // Clicking back button is Safari returns the page to previous state without reloading
      // We can "reset" the page here
      if (event.persisted) {
        setIsAddToCartLoading(false);
      }
    }

    window.addEventListener("pageshow", forceRefresh, false);
    return function () {
      return window.removeEventListener("pageshow", forceRefresh);
    };
  }, []);
  return ___EmotionJSX(Flex, {
    marginY: "20px",
    justifyContent: "space-between",
    alignItems: "center"
  }, ___EmotionJSX(WishlistButton, {
    onWishlistClick: onWishlistClick,
    inWishlist: inWishlist
  }), ___EmotionJSX(AddToCartButton, {
    flexGrow: 1,
    p: "15px",
    variant: "primary",
    height: "100%",
    marginX: 1,
    color: "white",
    fontSize: {
      xs: "lg",
      xl: "2xl"
    },
    textTransform: "uppercase",
    quantity: chosenQuantity,
    underlineOnHover: false,
    offerId: offerId,
    title: title,
    imageUrl: imageUrl,
    disabled: isAddToCartLoading,
    sourceId: sourceId,
    sourceType: sourceType,
    sourcePosition: sourcePosition,
    eventId: eventId ? parseInt(eventId) : undefined,
    isFormValid: isFormValid,
    type: type,
    getValues: getValues,
    searchTrackingToken: searchTrackingToken
  }, "Add to Cart"), ___EmotionJSX(BuyNowButton, {
    "aria-label": "Buy now",
    flexGrow: 1,
    p: "15px",
    variant: "secondary",
    height: "100%",
    color: "white",
    fontSize: {
      xs: "lg",
      xl: "2xl"
    },
    textTransform: "uppercase",
    quantity: chosenQuantity,
    underlineOnHover: false,
    offerId: offerId,
    title: title,
    imageUrl: imageUrl,
    setLoading: function setLoading() {
      return setIsBuyNowLoading(true);
    },
    setError: function setError() {
      return setIsBuyNowLoading(false);
    },
    disabled: isBuyNowLoading,
    noToast: true,
    redirectUrl: "/checkout/buy-now",
    sourceId: sourceId,
    sourceType: sourceType,
    sourcePosition: sourcePosition,
    eventId: parseInt(eventId || "1"),
    fetchPolicy: "no-cache",
    isFormValid: isFormValid,
    type: type,
    getValues: getValues
  }, "Buy Now", isBuyNowLoading && ___EmotionJSX(Span, {
    display: "inline-block",
    ml: "6px",
    mb: "-1px"
  }, ___EmotionJSX(LoadingSpinner, {
    width: "16px",
    height: "16px",
    ringSize: "2px",
    title: "Buying now.."
  }))));
};