import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["pattern", "valueAsNumber", "valueAsDate"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import { useFormContext, useFormState, useWatch } from "react-hook-form";
import { Textarea } from "../Textarea";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var FormTextarea = function FormTextarea(props) {
  var _useFormContext = useFormContext(),
      register = _useFormContext.register;

  var value = useWatch({
    name: props.name,
    defaultValue: ""
  });

  var _useFormState = useFormState(),
      errors = _useFormState.errors;

  var _ref = props.register || {},
      pattern = _ref.pattern,
      valueAsNumber = _ref.valueAsNumber,
      valueAsDate = _ref.valueAsDate,
      restRegisterProp = _objectWithoutProperties(_ref, _excluded);

  var registerOptions = _objectSpread({
    onChange: props.onChange,
    disabled: props.disabled,
    required: props.required
  }, restRegisterProp); // Need to set explicitly to satisfy TS discriminated union.
  // @see node_modules/react-hook-form/dist/types/validator.d.ts


  if (pattern && !valueAsNumber && !valueAsNumber) {
    registerOptions.pattern = pattern;
  } else if (valueAsNumber && !valueAsDate) {
    registerOptions.valueAsNumber = valueAsNumber;
  } else if (valueAsDate) {
    registerOptions.valueAsDate = valueAsDate;
  }

  var utils = register(props.name, registerOptions);
  return ___EmotionJSX(Textarea, _extends({
    error: Boolean(errors[props.name])
  }, utils, props, {
    value: value,
    ref: utils.ref,
    onChange: utils.onChange,
    onBlur: utils.onBlur
  }));
};
FormTextarea.displayName = "FormTextarea";
export default FormTextarea;