import React from "react";
import { Box, IconOnePassDelivery, Span } from "@catchoftheday/cg-components";
import { UpsellCTAWidgetContainer } from "@catchoftheday/onepass";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var OnePassDelivery = function OnePassDelivery(_ref) {
  var active = _ref.active,
      clubExclusive = _ref.clubExclusive;
  return active ? ___EmotionJSX(Box, {
    color: "onePass.textPrimary",
    display: "inline-flex",
    p: "8px 8px 12px",
    width: "100%"
  }, ___EmotionJSX(IconOnePassDelivery, {
    title: "OnePass free delivery",
    color: "onePass.primary",
    width: "20px",
    height: "16px",
    mt: "3px"
  }), ___EmotionJSX(Span, {
    fontSize: "sm",
    fontWeight: active ? "bold" : "medium",
    fontFamily: "onePass",
    ml: "8px"
  }, "You get free delivery on this item")) : clubExclusive ? null : ___EmotionJSX(UpsellCTAWidgetContainer, null);
};