import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var SpecificationsContainer = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  p {\n    margin-top: 0;\n  }\n"])));
export var Specifications = function Specifications(_ref) {
  var specifications = _ref.specifications;

  var _useState = useState(false),
      showSpecifications = _useState[0],
      setShowSpecifications = _useState[1];

  return ___EmotionJSX(SpecificationsContainer, {
    mb: "20px"
  }, ___EmotionJSX(Accordion, {
    expanded: showSpecifications,
    onChange: function onChange() {
      return setShowSpecifications(function (show) {
        return !show;
      });
    }
  }, ___EmotionJSX(AccordionSummary, {
    p: "16px 0"
  }, "A Bit About The Brand / More Info"), ___EmotionJSX(AccordionDetails, {
    p: "0 0 6px",
    dangerouslySetInnerHTML: {
      __html: specifications
    }
  })));
};