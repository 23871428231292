import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["onWishlistClick", "inWishlist"];
import React from "react";
import { Box, Button, IconHeart, IconHeartOutline } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var WishlistButton = function WishlistButton(_ref) {
  var onWishlistClick = _ref.onWishlistClick,
      inWishlist = _ref.inWishlist,
      buttonProps = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Button, _extends({
    variant: "secondary",
    "aria-label": "Add to wishlist",
    outline: true,
    width: {
      xs: "60px",
      xl: "65px"
    },
    height: {
      xs: "60px",
      xl: "65px"
    },
    minWidth: "auto!important",
    sizeVariant: {
      xs: "md",
      lg: "xl"
    },
    position: "relative",
    onClick: function onClick() {
      return onWishlistClick();
    },
    type: "button"
  }, buttonProps), ___EmotionJSX(Box, {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-22px",
    marginLeft: "-22px"
  }, inWishlist ? ___EmotionJSX(IconHeart, {
    width: "46px",
    height: "46px"
  }) : ___EmotionJSX(IconHeartOutline, {
    width: "46px",
    height: "46px"
  })));
};