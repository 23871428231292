import React from "react";
import { ContentPlaceHolder } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var BuyBoxLoader = function BuyBoxLoader() {
  return ___EmotionJSX(ContentPlaceHolder, {
    speed: 2,
    viewBox: "0 0 480 242",
    backgroundColor: "#f3f3f3",
    foregroundColor: "#ecebeb",
    "aria-label": "Loading"
  }, ___EmotionJSX("rect", {
    x: "0",
    y: "0",
    rx: "3",
    ry: "3",
    width: "160",
    height: "60"
  }), ___EmotionJSX("rect", {
    x: "0",
    y: "70",
    rx: "3",
    ry: "3",
    width: "100%",
    height: "60"
  }), ___EmotionJSX("rect", {
    x: "0",
    y: "140",
    rx: "3",
    ry: "3",
    width: "100%",
    height: "60"
  }), ___EmotionJSX("rect", {
    x: "12.5%",
    y: "216",
    rx: "3",
    ry: "3",
    width: "75%",
    height: "24"
  }));
};