import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { Flex, IconTick } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var ListItemContainer = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  padding: 10px;\n  &:hover {\n    color: ", ";\n    background-color: ", ";\n  }\n"])), function (props) {
  return "justify-content: ".concat(props.isSelectedLocation ? "space-between" : "flex-start", ";\n      background-color: ").concat(props.isHighlighted ? themeGet("colors.ui.dropDownBg")(props) : "white", ";\n      color: ").concat(props.isErrorMessage ? themeGet("colors.error")(props) : props.isHighlighted || props.isSelectedLocation ? themeGet("colors.ui.dropDownText")(props) : themeGet("colors.text")(props), ";\n    ");
}, function (props) {
  return props.isErrorMessage ? themeGet("colors.error")(props) : themeGet("colors.ui.dropDownText")(props);
}, function (props) {
  return props.isErrorMessage ? "white" : themeGet("colors.ui.dropDownBg");
});
export var renderListItem = function renderListItem(_ref) {
  var _itemData$value, _itemData$value2;

  var itemData = _ref.itemData,
      isHighlighted = _ref.isHighlighted;
  var isErrorMessage = (_itemData$value = itemData.value) === null || _itemData$value === void 0 ? void 0 : _itemData$value.isErrorMessage;
  var isSelectedLocation = (_itemData$value2 = itemData.value) === null || _itemData$value2 === void 0 ? void 0 : _itemData$value2.isSelectedLocation;
  return ___EmotionJSX(ListItemContainer, {
    isHighlighted: isHighlighted && !isErrorMessage && !isSelectedLocation,
    fontWeight: "light",
    isErrorMessage: isErrorMessage || false,
    isSelectedLocation: isSelectedLocation || false
  }, itemData.title, isSelectedLocation && ___EmotionJSX(IconTick, {
    width: "10px",
    height: "17px",
    "aria-hidden": "true",
    "data-testid": "selected-location-tick"
  }));
};