import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Icon } from "../../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconLatitudePayGrey = function IconLatitudePayGrey(props) {
  return ___EmotionJSX(Icon, _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 200 26.9"
  }, props), ___EmotionJSX("path", {
    d: "M30.94,3.42a2.3,2.3,0,0,1-4.58,0,2.3,2.3,0,0,1,4.58,0ZM28.65,8a2.22,2.22,0,0,0-2.29,2.13,2.3,2.3,0,0,0,4.58,0A2.21,2.21,0,0,0,28.65,8ZM14.26,5.55a2.21,2.21,0,0,0,2.28-2.13,2.21,2.21,0,0,0-2.28-2.13A2.21,2.21,0,0,0,12,3.42,2.2,2.2,0,0,0,14.26,5.55Zm14.39,9.14a2.14,2.14,0,1,0,2.29,2.13A2.22,2.22,0,0,0,28.65,14.69Zm-7.2-9.14a2.21,2.21,0,0,0,2.29-2.13,2.3,2.3,0,0,0-4.58,0A2.21,2.21,0,0,0,21.45,5.55ZM9.35,1.29H4.77V25.66H30.94V21.39H9.35ZM47.62,8.1H46V18.83h8.36V17.3H47.62Zm104,1.53V8.1H143.3V18.83h8.36V17.3H145V14.23h5.77V12.7H145V9.63Zm-63.23,9.2h1.64V8.1H88.43Zm7.37-9.2h3.94v9.2h1.65V9.63h3.94V8.1H95.8V9.63Zm-22.64,0h4v9.2h1.64V9.63h3.94V8.1H73.16V9.63ZM65.4,8.1l5.23,10.73H68.9l-1.44-3.06H61.69l-1.45,3.06H58.55L63.77,8.1Zm1.34,6.13-2.17-4.6-2.16,4.6Zm71-.76c0,3.45-2.72,5.36-6.07,5.36H127V8.1h4.74C135.05,8.1,137.77,10,137.77,13.47Zm-1.67,0c0-2.46-2-3.84-4.51-3.84h-3V17.3h3C134.1,17.3,136.1,15.93,136.1,13.47Zm-17,.5c0,1.94-.82,3.46-3.22,3.46s-3.23-1.52-3.23-3.46V8.1H111v6.1c0,2.07.88,4.76,4.87,4.76s4.87-2.7,4.87-4.76V8.1h-1.65Z"
  }), ___EmotionJSX("path", {
    d: "M167.56,9.09a3.36,3.36,0,0,1,1.22,2.77,3.69,3.69,0,0,1-1.22,3,5.07,5.07,0,0,1-3.42,1h-2.06v3h-3V8.1h5.07A5.28,5.28,0,0,1,167.56,9.09Zm-2.15,4.11a1.53,1.53,0,0,0,.5-1.24,1.48,1.48,0,0,0-.5-1.2,2.15,2.15,0,0,0-1.42-.43h-1.91v3.29H164A2.09,2.09,0,0,0,165.41,13.2Z"
  }), ___EmotionJSX("path", {
    d: "M181.05,18.83l-.79-1.9h-5l-.8,1.9h-3.1l5-10.73h3.09l4.83,10.73Zm-4.86-4h3.18L177.8,11Z"
  }), ___EmotionJSX("path", {
    d: "M197.29,8.1l-4.16,7.3v3.43h-3V15.48L185.88,8.1h3l2.73,4.61,2.66-4.61Z"
  }));
};