import _toConsumableArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["productId", "ratingsSummary"];
import React, { useCallback, useEffect, useState } from "react";
import { Box, Flex, Heading, Span, Tab, TabList, TabPanel, Tabs } from "@catchoftheday/cg-components";
import { useProductReviewsQuery } from "@product-app/generated/graphql";
import { ReviewList } from "./ReviewList";
import { ReviewStatistics } from "./ReviewStatistics";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Reviews = function Reviews(_ref) {
  var productId = _ref.productId,
      ratingsSummary = _ref.ratingsSummary,
      boxProps = _objectWithoutProperties(_ref, _excluded);

  var _useState = useState(Array.from({
    length: 6
  }, function () {
    return {
      offset: 0,
      reviews: []
    };
  })),
      reviews = _useState[0],
      setReviews = _useState[1];

  var _useState2 = useState(0),
      selectedTabIndex = _useState2[0],
      setSelectedTabIndex = _useState2[1];

  var _useState3 = useState([]),
      activeTabs = _useState3[0],
      setActiveTabs = _useState3[1];

  var limit = 5;
  var reviewTabs = [5, 4, 3, 2, 1, 0];

  function isActive(rating) {
    return activeTabs.some(function (tab) {
      return tab.rating == rating;
    });
  }

  var _useProductReviewsQue = useProductReviewsQuery({
    variables: {
      productId: productId,
      offset: 0,
      limit: limit,
      rating: reviewTabs[selectedTabIndex] > 0 ? reviewTabs[selectedTabIndex] : undefined
    },
    notifyOnNetworkStatusChange: true,
    ssr: false
  }),
      data = _useProductReviewsQue.data,
      loading = _useProductReviewsQue.loading,
      refetch = _useProductReviewsQue.refetch;

  function setSelectedTab(tabIndex) {
    setSelectedTabIndex(tabIndex);

    if (reviews[tabIndex].reviews.length === 0) {
      getMoreReviews();
    }
  }

  var updateReviews = useCallback(function (response) {
    setReviews(function (prevReviews) {
      var reviews = _toConsumableArray(prevReviews);

      reviews[selectedTabIndex].reviews = _toConsumableArray(reviews[selectedTabIndex].reviews.concat(response));
      return reviews;
    });
  }, [selectedTabIndex]);
  var updateOffset = useCallback(function () {
    setReviews(function (prevReviews) {
      var reviews = _toConsumableArray(prevReviews);

      reviews[selectedTabIndex].offset = reviews[selectedTabIndex].offset + limit;
      return reviews;
    });
  }, [selectedTabIndex]);
  var getMoreReviews = useCallback(function (offset) {
    refetch({
      offset: offset
    });

    if (offset) {
      updateOffset();
    }
  }, [refetch, updateOffset]);
  var reviewsExists = useCallback(function (response) {
    var exists = false;
    reviews[selectedTabIndex].reviews.forEach(function (value) {
      if (response.find(function (item) {
        return item.id == value.id;
      })) {
        exists = true;
        return;
      }
    });
    return exists;
  }, [reviews, selectedTabIndex]);
  useEffect(function () {
    var _data$productReviews;

    var response = data === null || data === void 0 ? void 0 : (_data$productReviews = data.productReviews) === null || _data$productReviews === void 0 ? void 0 : _data$productReviews.reviews;

    if (response !== null && response !== void 0 && response.length && !reviewsExists(response)) {
      updateReviews(response);
    }
  }, [data, updateReviews, reviewsExists]);
  useEffect(function () {
    setActiveTabs(ratingsSummary.breakdown.filter(function (rating) {
      return rating.total > 0;
    }));
    setSelectedTabIndex(ratingsSummary.breakdown.findIndex(function (item) {
      return item.total > 0;
    }));
  }, [ratingsSummary.breakdown]);
  return ___EmotionJSX(Box, boxProps, ___EmotionJSX(Flex, null, ___EmotionJSX(Box, {
    px: 4,
    py: 3,
    borderTop: "5px solid",
    borderTopColor: "secondary",
    borderRight: "1px solid",
    borderRightColor: "darkGrey",
    borderLeft: "1px solid",
    borderLeftColor: "darkGrey"
  }, ___EmotionJSX(Heading.h3, {
    m: "0",
    fontSize: "lg"
  }, "Product Reviews")), ___EmotionJSX(Box, {
    borderBottom: "1px solid",
    borderBottomColor: "darkGrey",
    flexGrow: 1
  })), ___EmotionJSX(Flex, {
    flexWrap: "wrap",
    flexDirection: {
      xs: "column-reverse",
      lg: "row"
    }
  }, ___EmotionJSX(Box, {
    width: {
      _: "100%",
      lg: "50%"
    }
  }, ___EmotionJSX(Tabs, {
    selectedIndex: selectedTabIndex,
    onSelect: function onSelect(index) {
      return setSelectedTab(index);
    }
  }, ___EmotionJSX(TabList, {
    m: "12px 0 0"
  }, reviewTabs.map(function (tab) {
    return ___EmotionJSX(Tab, {
      key: "tab-".concat(tab),
      disabled: !isActive(tab) && tab != 0,
      marginRight: {
        xs: 4,
        md: 8
      }
    }, ___EmotionJSX(Span, {
      fontSize: "base",
      textAlign: "center",
      display: "inline-block"
    }, tab == 0 ? "ALL" : "".concat(tab, " STAR")));
  })), reviews.map(function (item, index) {
    var _ratingsSummary$break;

    return ___EmotionJSX(TabPanel, {
      key: index
    }, ___EmotionJSX(ReviewList, {
      reviews: item.reviews,
      reviewCount: index == 5 ? ratingsSummary.numberOfRatings : (_ratingsSummary$break = ratingsSummary.breakdown[selectedTabIndex]) === null || _ratingsSummary$break === void 0 ? void 0 : _ratingsSummary$break.total,
      onShowMore: function onShowMore() {
        getMoreReviews(item.offset + limit);
      },
      loading: loading
    }));
  }))), ___EmotionJSX(Box, {
    width: {
      _: "100%",
      lg: "50%"
    },
    pt: "60px",
    pl: {
      _: 0,
      lg: "40px"
    }
  }, ___EmotionJSX(ReviewStatistics, {
    mt: 0,
    averageRating: ratingsSummary.averageRating,
    reviewCount: ratingsSummary.numberOfRatings,
    summary: ratingsSummary.breakdown,
    setSelectedTab: setSelectedTab
  }))));
};