import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";

/**
 * Wrapped version of fslightbox-react
 * Documentation: https://fslightbox.com/react/documentation
 */
import React, { useEffect, useRef, useState } from "react";
import FsLightbox from "fslightbox-react";
import { jsx as ___EmotionJSX } from "@emotion/react";

var ExtendedLighbox = function ExtendedLighbox(_ref) {
  var props = _extends({}, _ref);

  return ___EmotionJSX(FsLightbox, props);
};

export var Lightbox = function Lightbox(_ref2) {
  var open = _ref2.open,
      _onClose = _ref2.onClose,
      sources = _ref2.sources,
      sourceIndex = _ref2.sourceIndex,
      customAttributes = _ref2.customAttributes;
  // Tracks the true open state of the modal by hooking into open/close events
  var trueOpenRef = useRef(false); // Interact with the default toggling behaviour of fslightbox-react

  var _useState = useState(open),
      toggler = _useState[0],
      toggle = _useState[1]; // If the true open state does not match the intended open state giving by the user, we need to toggle the true state to match the intended state


  useEffect(function () {
    if (trueOpenRef.current !== open) {
      toggle(function (x) {
        return !x;
      });
    }
  }, [open]); // Parse the source strings to create custom sources if needed, eg for orbitvu

  var parsedSources = parseSources(sources);
  var types = parsedSources.map(getType);
  return ___EmotionJSX(ExtendedLighbox, {
    types: types,
    sources: parsedSources,
    sourceIndex: sourceIndex,
    toggler: toggler,
    onOpen: function onOpen() {
      trueOpenRef.current = true;
    },
    onClose: function onClose() {
      trueOpenRef.current = false;
      _onClose === null || _onClose === void 0 ? void 0 : _onClose();
    },
    customAttributes: customAttributes
  });
};
Lightbox.displayName = "Lightbox";
export default Lightbox;
/** Determines if a url is an orbitvu link */

export function isOrbitvu(src) {
  return src.startsWith("https://orbitvu.co");
}
/** Determines if a url is a youtube link */

export function isYoutube(src) {
  return src.startsWith("https://www.youtube.com");
} // https://fslightbox.com/react/documentation/types
// We can use 'custom' when we have a custom source even though it is invalid because a custom source doesn't care about the type anyway

// We only support orbitvu/custom, youtube and image
function getType(source) {
  if (typeof source !== "string") {
    return "custom";
  }

  return isOrbitvu(source) ? "custom" : isYoutube(source) ? "youtube" : "image";
}

function parseSources(sources) {
  return sources.map(function (source, idx) {
    if (typeof source !== "string") {
      return source;
    }

    if (isOrbitvu(source)) {
      return ___EmotionJSX("iframe", {
        key: "lightbox-iframe-".concat(idx),
        title: "Orbitvu 360 product image viewer",
        src: source,
        width: "1920px",
        height: "1080px",
        frameBorder: "0"
      });
    }

    return source;
  });
}