import { formatLocation } from "./location.js";
export var transformLocationsToDropdownData = function transformLocationsToDropdownData(locations) {
  return locations.map(function (location) {
    var _location$stateCode;

    return {
      value: {
        isErrorMessage: false,
        isSelectedLocation: false,
        locationId: location.id
      },
      title: formatLocation({
        suburb: location.suburb,
        state: (_location$stateCode = location.stateCode) !== null && _location$stateCode !== void 0 ? _location$stateCode : "",
        postcode: location.postcode
      })
    };
  });
};