import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Icon } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconPlay = function IconPlay(props) {
  return ___EmotionJSX(Icon, _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 314.45 217.95",
    fill: "currentColor"
  }, props), ___EmotionJSX("path", {
    d: "M306.15,66H43.85A26.08,26.08,0,0,0,17.77,92.11V257.89A26.08,26.08,0,0,0,43.85,284h262.3a26.08,26.08,0,0,0,26.08-26.08V92.11A26.08,26.08,0,0,0,306.15,66ZM144.57,237V113l88.17,62Z",
    transform: "translate(-17.77 -66.03)"
  }));
};