import { LOCATION_PLACEHOLDER } from "../../constants.js";
import { transformLocationsToDropdownData } from "./transformLocationsToDropdownData.js";
export var SEARCH_SERVICE_NOT_AVAILABLE = "Search service not available";
export var USER_INPUT_PROMPT = "Please input a valid postcode or suburb";
export var NO_LOCATION_DATA = [{
  value: {
    isErrorMessage: true,
    isSelectedLocation: false
  },
  title: USER_INPUT_PROMPT,
  unclickable: true
}];
export var ERROR_DATA = [{
  value: {
    isErrorMessage: true,
    isSelectedLocation: false
  },
  title: SEARCH_SERVICE_NOT_AVAILABLE,
  unclickable: true
}];
export var getSelectedLocationData = function getSelectedLocationData(selectedLocation) {
  return [{
    value: {
      isErrorMessage: false,
      isSelectedLocation: true
    },
    title: selectedLocation,
    unclickable: true
  }];
};
export var getDropdownData = function getDropdownData(_ref) {
  var searchTermLength = _ref.searchTermLength,
      selectedLocation = _ref.selectedLocation,
      locations = _ref.locations,
      error = _ref.error;
  if (!searchTermLength) return selectedLocation === LOCATION_PLACEHOLDER ? [] : getSelectedLocationData(selectedLocation);
  if (locations !== null && locations !== void 0 && locations.length) return transformLocationsToDropdownData(locations);
  if ((locations === null || locations === void 0 ? void 0 : locations.length) === 0) return NO_LOCATION_DATA;
  if (error) return ERROR_DATA;
  return [];
};