import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import { Tab as ReactTab } from "react-tabs";
import { styled, themeGet } from "@catchoftheday/theme";
import { listItemStyleProps } from "../ListItem";
export var Tab = styled(ReactTab)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  list-style: none;\n  padding: 10px 0;\n  margin-right: 32px;\n  cursor: pointer;\n  transform: translateY(2px);\n  color: ", ";\n  &:last-of-type {\n    margin-right: 0;\n  }\n  &.__catch-tab-selected__ {\n    color: ", ";\n    border-bottom: 2px solid ", ";\n    outline: none;\n    ", "\n  }\n\n  &.__catch-tab-disabled__ {\n    cursor: not-allowed;\n    color: ", ";\n    ", "\n  }\n\n  ", "\n"])), themeGet("colors.typography.subText"), themeGet("colors.typography.text"), themeGet("colors.secondary"), function (props) {
  return props.selectedCss;
}, themeGet("colors.grey"), function (props) {
  return props.disabledCss;
}, listItemStyleProps);
Tab.defaultProps = {
  selectedClassName: "__catch-tab-selected__",
  disabledClassName: "__catch-tab-disabled__"
};
Tab.tabsRole = "Tab";
export default Tab;