import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

import React, { useContext, useEffect, useRef, useState } from "react";
import useWebSocket from "react-use-websocket";
import { Box, isOrbitvu, NoSSR } from "@catchoftheday/cg-components";
import { ErrorPage } from "@catchoftheday/common-pages";
import { AdultBlurContainer, AdultModal, AppContextProvider, CartContextProvider, GlobalContextProvider, PageBanner, ScrollToTop, SponsoredDisclaimerModal, triggerAdultModal, WishlistContextProvider } from "@catchoftheday/common-vendors";
import { CustomerContext, CustomerContextProvider } from "@catchoftheday/customer";
import { getEnvConfig } from "@catchoftheday/env-config";
import { EnhancedHeader, Footer } from "@catchoftheday/header";
import { SocialUrgency } from "@catchoftheday/p13n-integration";
import { RecommenderContainer } from "@catchoftheday/personalisation";
import { idFromSlug, ProductPriceDisclaimerModal } from "@catchoftheday/product-components";
import { PageViewPayload, usePageViewMessage, useSocialUrgencyMessage } from "@catchoftheday/state";
import { minusOnePx, styled, themeGet, useMedia, useTheme } from "@catchoftheday/theme";
import { centsToDollars, getCookie, getSalesChannel, parseQueryString } from "@catchoftheday/utilities";
import { Breadcrumbs } from "@product-app/components/Breadcrumbs";
import { SizeChartsContextProvider, SizeChartsLightbox } from "@product-app/components/BuyBox/SizeCharts";
import { Gallery } from "@product-app/components/Gallery";
import { PopularSearches } from "@product-app/components/PopularSearches";
import { ProductDescription } from "@product-app/components/ProductDescription";
import { ProductHeader } from "@product-app/components/ProductHeader";
import { Reviews } from "@product-app/components/Reviews";
import { RECOMMENDER_SOURCE_ID } from "@product-app/consts";
import { generateBreadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import { BuyBox } from "../BuyBox";
import { ProductPageMetaTags, ProductPageSEO } from "./ProductPageSEO";
import { jsx as ___EmotionJSX } from "@emotion/react";
var ReviewContainer = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  @media (min-width: ", ") {\n    :target::before {\n      content: \"\";\n      display: block;\n      height: 120px;\n      margin: -120px 0 0;\n    }\n  }\n"])), themeGet("breakpoints.lg"));

var ProductPageContent = function ProductPageContent(_ref) {
  var _customerData$metaDat, _product$selectedOffe, _offer$retailPriceDis;

  var product = _ref.product,
      eventSlug = _ref.eventSlug,
      productSlug = _ref.productSlug,
      salesChannel = _ref.salesChannel,
      host = _ref.host;

  var _useState = useState(),
      requestedOfferId = _useState[0],
      setRequestedOfferId = _useState[1];

  var headerRef = useRef(null);

  var _useContext = useContext(CustomerContext),
      customerData = _useContext.data,
      customerLoading = _useContext.loading;

  var eventId = eventSlug && idFromSlug(eventSlug);

  var _useTheme = useTheme(),
      breakpoints = _useTheme.breakpoints;

  var isMobile = !!useMedia(["(max-width: ".concat(minusOnePx(breakpoints.md, true), ")")], [true]);
  var isTablet = !!useMedia(["(max-width: ".concat(minusOnePx(breakpoints.lg, true), ")")], [true]);
  var id = product.id,
      title = product.title,
      adult = product.adult,
      ratingsSummary = product.ratingsSummary,
      description = product.description,
      specifications = product.specifications,
      footer = product.footer,
      brand = product.brand,
      assets = product.assets,
      offer = product.selectedOffer,
      category = product.category;

  var _useWebSocket = useWebSocket(getEnvConfig().SOCIAL_URGENCY_WEBSOCKET_URL),
      sendJsonMessage = _useWebSocket.sendJsonMessage,
      lastJsonMessage = _useWebSocket.lastJsonMessage;

  usePageViewMessage({
    sendMessage: sendJsonMessage,
    pageViewPayload: new PageViewPayload({
      pageType: "product",
      offerId: offer !== null && offer !== void 0 && offer.id ? Number(offer === null || offer === void 0 ? void 0 : offer.id) : undefined,
      eventId: eventId ? Number(eventId) : undefined,
      productId: Number(id),
      salesChannel: salesChannel,
      customerUuid: (customerData === null || customerData === void 0 ? void 0 : (_customerData$metaDat = customerData.metaData) === null || _customerData$metaDat === void 0 ? void 0 : _customerData$metaDat.customerUuid) || undefined
    }),
    loading: customerLoading
  });
  var lastSocialUrgencyMessage = useSocialUrgencyMessage({
    sendMessage: sendJsonMessage,
    lastMessage: lastJsonMessage,
    topicInputs: [{
      id: id,
      context: "product"
    }],
    salesChannelCode: salesChannel
  });
  var fromProduct = {
    from_product_ids: [Number(id)],
    from_product_offer_id: offer ? Number(offer.id) : null,
    from_product_displayed_price: offer !== null && offer !== void 0 && offer.sellPrice.amount ? centsToDollars(offer === null || offer === void 0 ? void 0 : offer.sellPrice.amount) : null
  };
  useEffect(function () {
    if (adult) {
      var adultCookie = getCookie("over18", false);

      if (!adultCookie) {
        triggerAdultModal({
          open: true
        });
      }
    }
  }, [adult]);
  useEffect(function () {
    var queryStrings = parseQueryString(window.location.search);

    if (queryStrings.offer_id) {
      setRequestedOfferId(queryStrings.offer_id);
    }
  }, []);
  return ___EmotionJSX(Box, {
    as: "section",
    itemType: "http://schema.org/Product",
    itemScope: true
  }, ___EmotionJSX(ProductPageMetaTags, {
    product: product,
    host: host,
    condition: (_product$selectedOffe = product.selectedOffer) === null || _product$selectedOffe === void 0 ? void 0 : _product$selectedOffe.conditionCode
  }), ___EmotionJSX(AdultModal, {
    adult: adult
  }), ___EmotionJSX(ProductPriceDisclaimerModal, null), ___EmotionJSX(SponsoredDisclaimerModal, null), ___EmotionJSX(AdultBlurContainer, {
    adult: adult
  }, ___EmotionJSX(ContentContainer, null, ___EmotionJSX(PageBanner, {
    host: host,
    isMobile: isMobile,
    pageType: "PRODUCT",
    mb: 0,
    mx: 2,
    pt: 4
  }), category && ___EmotionJSX(Breadcrumbs, {
    category: category,
    eventSlug: eventSlug
  })), ___EmotionJSX(ContentContainer, {
    position: "relative",
    px: 2,
    pb: 2
  }, !isMobile && ___EmotionJSX(Box, {
    mb: "20px",
    px: "10px",
    ref: headerRef
  }, ___EmotionJSX(ProductHeader, {
    title: title,
    brand: brand ? {
      name: brand.name,
      href: "/brand/".concat(brand.slug)
    } : undefined,
    averageReview: ratingsSummary.averageRating,
    reviewCount: ratingsSummary.numberOfRatings,
    width: "100%"
  })), ___EmotionJSX(Box, {
    as: "section",
    width: {
      xs: "100%",
      lg: "60%"
    },
    "float": "left",
    px: "10px"
  }, ___EmotionJSX(Gallery, {
    items: parseGalleryItems(assets.gallery),
    productId: id,
    offerId: offer === null || offer === void 0 ? void 0 : offer.id,
    onePassExclusive: offer === null || offer === void 0 ? void 0 : offer.clubExclusive
  }), isMobile && ___EmotionJSX(ProductHeader, {
    align: "center",
    mt: "30px",
    px: "10px",
    title: title,
    brand: brand ? {
      name: brand.name,
      href: "/brand/".concat(brand.slug)
    } : undefined,
    averageReview: ratingsSummary.averageRating,
    reviewCount: ratingsSummary.numberOfRatings,
    width: "100%"
  })), ___EmotionJSX(SizeChartsContextProvider, null, ___EmotionJSX(Box, {
    as: "section",
    width: {
      xs: "100%",
      lg: "40%"
    },
    "float": "right",
    px: "10px"
  }, ___EmotionJSX(SocialUrgency, {
    stats: lastSocialUrgencyMessage.id === id ? lastSocialUrgencyMessage.stats : null
  }), ___EmotionJSX(BuyBox, {
    isTablet: isTablet,
    productId: id,
    offerId: requestedOfferId || (offer === null || offer === void 0 ? void 0 : offer.id),
    eventId: eventId,
    headerRef: headerRef
  })), ___EmotionJSX(SizeChartsLightbox, null)), ___EmotionJSX(Box, {
    as: "section",
    width: {
      xs: "100%",
      lg: "60%"
    },
    px: "10px",
    py: "20px",
    display: "inline-block"
  }, ___EmotionJSX(ProductDescription, {
    description: description,
    footer: footer,
    specifications: specifications,
    retailPriceDisclaimer: offer === null || offer === void 0 ? void 0 : (_offer$retailPriceDis = offer.retailPriceDisclaimer) === null || _offer$retailPriceDis === void 0 ? void 0 : _offer$retailPriceDis.description
  }))), ___EmotionJSX(RecommenderContainer, {
    brand: brand ? {
      name: brand.name,
      slug: brand.slug
    } : undefined,
    offer: offer,
    productId: id,
    adult: adult,
    fromProduct: fromProduct,
    sourceId: RECOMMENDER_SOURCE_ID,
    category: generateBreadcrumbs(category).map(function (cat) {
      return cat.label;
    }).join(" > "),
    host: host
  }), ___EmotionJSX(NoSSR, null, ratingsSummary.numberOfRatings > 0 && ___EmotionJSX(ReviewContainer, {
    id: "reviews"
  }, ___EmotionJSX(ContentContainer, null, ___EmotionJSX(Reviews, {
    p: "30px 10px",
    productId: id,
    ratingsSummary: ratingsSummary
  })))), ___EmotionJSX(PopularSearches, {
    productSlug: productSlug
  })), ___EmotionJSX(ScrollToTop, null));
};

export var ProductPage = function ProductPage(_ref2) {
  var host = _ref2.host,
      productSlug = _ref2.productSlug,
      eventSlug = _ref2.eventSlug,
      data = _ref2.data,
      hasError = _ref2.hasError;

  if (hasError) {
    return ___EmotionJSX(ErrorPage, {
      host: host
    });
  }

  var product = data === null || data === void 0 ? void 0 : data.productById;
  return ___EmotionJSX(React.Fragment, null, product && ___EmotionJSX(ProductPageSEO, {
    product: product,
    host: host
  }), ___EmotionJSX(AppContextProvider, {
    contexts: [CustomerContextProvider, WishlistContextProvider, CartContextProvider, GlobalContextProvider]
  }, ___EmotionJSX(EnhancedHeader, {
    host: host
  }), product && ___EmotionJSX(ProductPageContent, {
    product: product,
    eventSlug: eventSlug,
    productSlug: productSlug,
    salesChannel: getSalesChannel(host),
    host: host
  })), ___EmotionJSX(Footer, null));
};

function parseGalleryItems(items) {
  return items.map(function (item, index, array) {
    var type = item.__typename === "Video" ? isOrbitvu(item.url) ? "orbitvu" : "youtube" : "image";
    var src = item.url;
    return {
      index: index,
      previewUrl: type !== "image" ? array[0].url : src,
      src: src,
      altText: item.altText,
      type: type
    };
  });
}

var ContentContainer = styled(Box)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin: auto;\n  max-width: 1340px;\n"])));