import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { MetaTags } from "@catchoftheday/common-vendors";
import { centsToDollars } from "@catchoftheday/utilities";
import { SEO } from "../SEO";
import { jsx as ___EmotionJSX } from "@emotion/react";
var conditionMap = {
  NEW: "NewCondition",
  REFURBISHED: "RefurbishedCondition"
};
export var ProductPageSEO = function ProductPageSEO(_ref) {
  var _product$metaURLs, _product$metaURLs2;

  var product = _ref.product,
      host = _ref.host;
  return ___EmotionJSX(SEO, {
    alternateTags: ((_product$metaURLs = product.metaURLs) === null || _product$metaURLs === void 0 ? void 0 : _product$metaURLs.alternate) || [],
    canonical: (_product$metaURLs2 = product.metaURLs) === null || _product$metaURLs2 === void 0 ? void 0 : _product$metaURLs2.canonical,
    description: product.title,
    domain: host,
    image: product.assets.hero.url,
    title: product.title
  });
};
export var ProductPageMetaTags = function ProductPageMetaTags(_ref2) {
  var _product$metaURLs3, _product$selectedOffe;

  var product = _ref2.product,
      condition = _ref2.condition;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(MetaTags, {
    tags: [{
      itemProp: "description",
      content: product.title
    }, {
      itemProp: "productID",
      content: product.id
    }]
  }), ___EmotionJSX(Box, {
    itemProp: "offers",
    itemScope: true,
    itemType: "http://schema.org/Offer"
  }, product.selectedOffer && ___EmotionJSX(MetaTags, {
    tags: [{
      itemProp: "price",
      content: "".concat(centsToDollars(product.selectedOffer.sellPrice.amount))
    }, {
      itemProp: "priceCurrency",
      content: product.selectedOffer.sellPrice.currency
    }, {
      itemProp: "availability",
      content: "http://schema.org/".concat(product.sellable ? "InStock" : "OutOfStock")
    }, {
      itemProp: "itemCondition",
      content: condition && conditionMap[condition]
    }]
  }), ___EmotionJSX("link", {
    itemProp: "url",
    href: "".concat((_product$metaURLs3 = product.metaURLs) === null || _product$metaURLs3 === void 0 ? void 0 : _product$metaURLs3.canonical.url, "?offer_id=").concat((_product$selectedOffe = product.selectedOffer) === null || _product$selectedOffe === void 0 ? void 0 : _product$selectedOffe.id)
  })));
};