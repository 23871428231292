import React, { useCallback } from "react";
import { useRouter } from "next/router";
import { emitUpsellNudgeAction, emitUpsellNudgeImpression } from "@catchoftheday/analytics";
import { Box, Button, Flex, Heading, IconOnePass, Link, Span } from "@catchoftheday/cg-components";
import { URL_LOGIN } from "@catchoftheday/common-vendors";
import { CTAButton as OnePassCTAButton, triggerOnePassLinkingModal, triggerOnePassUpsellModal } from "@catchoftheday/onepass";
import { useIsInViewport } from "@catchoftheday/utilities";
import { WishlistButton } from "../WishlistButton";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var OnePassExclusive = function OnePassExclusive(_ref) {
  var _customer$membership, _customer$membership$, _customer$membership2;

  var customer = _ref.customer,
      onToggleWishlist = _ref.onToggleWishlist,
      inWishlist = _ref.inWishlist;

  var _useRouter = useRouter(),
      asPath = _useRouter.asPath;

  var showFreeTrial = !customer || !customer.membership || ((_customer$membership = customer.membership) === null || _customer$membership === void 0 ? void 0 : (_customer$membership$ = _customer$membership.subscription) === null || _customer$membership$ === void 0 ? void 0 : _customer$membership$.freeTrialEligible);
  var showLogIn = !customer;
  var showOnePassLink = customer && !((_customer$membership2 = customer.membership) !== null && _customer$membership2 !== void 0 && _customer$membership2.linked);
  var showAlreadyAMember = showLogIn || showOnePassLink;
  var onMainCTAClick = useCallback(function () {
    emitUpsellNudgeAction(showFreeTrial ? "freeTrial" : "join");
    triggerOnePassUpsellModal();
  }, [showFreeTrial]);
  var onLinkButtonClick = useCallback(function () {
    emitUpsellNudgeAction("link");
    triggerOnePassLinkingModal({
      originalUrl: asPath
    });
  }, [asPath]);
  var onLoginClick = useCallback(function () {
    emitUpsellNudgeAction("login");
  }, []);
  var trackImpression = useCallback(function () {
    emitUpsellNudgeImpression();
  }, []);

  var _useIsInViewport = useIsInViewport({
    onComponentSeen: trackImpression
  }),
      upsellComponent = _useIsInViewport.ref;

  return ___EmotionJSX(Box, {
    ref: upsellComponent
  }, ___EmotionJSX(Flex, {
    flexDirection: "column",
    mt: {
      xs: "10px",
      md: "15px"
    },
    p: "20px 24px",
    fontFamily: "onePass",
    color: "onePass.textPrimary",
    borderRadius: "16px",
    borderColor: "onePass.primary",
    borderStyle: "solid",
    borderWidth: "1px",
    "aria-label": showFreeTrial ? "Start free 30 day trial" : "Join OnePass today",
    alignItems: "center"
  }, ___EmotionJSX(Flex, {
    mb: "6px"
  }, ___EmotionJSX(Heading.h4, {
    m: "0",
    fontWeight: "700"
  }, "Join", ___EmotionJSX(IconOnePass, {
    title: "OnePass",
    "aria-label": "OnePass",
    color: "onePass.primary",
    width: "82px",
    height: "18px",
    mx: "3px",
    mb: "7px"
  }), "to access this deal")), ___EmotionJSX(Span, {
    fontSize: "12px",
    fontWeight: "medium",
    lineHeight: "16px",
    mb: "16px"
  }, "Get free delivery on this item and more!"), ___EmotionJSX(Flex, {
    width: "100%"
  }, ___EmotionJSX(Box, {
    flex: "0 1 auto",
    mr: "12px"
  }, ___EmotionJSX(WishlistButton, {
    onWishlistClick: onToggleWishlist,
    inWishlist: inWishlist,
    width: "54px",
    height: "54px",
    sizeVariant: "md"
  })), ___EmotionJSX(Box, {
    flex: "1 0 auto"
  }, ___EmotionJSX(Button, {
    variant: "onePassPrimary",
    fontWeight: "600",
    width: "100%",
    onClick: onMainCTAClick
  }, showFreeTrial ? "Start free 30 day trial" : "Join OnePass today"))), showAlreadyAMember && ___EmotionJSX(Flex, {
    justifyContent: "center",
    mt: "12px"
  }, ___EmotionJSX(Span, {
    fontSize: "xs",
    fontWeight: "500",
    color: "onePass.textPrimary"
  }, "Already a member?"), showLogIn ? ___EmotionJSX(Link, {
    onClick: onLoginClick,
    href: URL_LOGIN,
    pl: "2px",
    fontSize: "xs",
    fontWeight: "bold",
    color: "onePass.primary",
    textDecoration: "underline"
  }, "Log in") : ___EmotionJSX(OnePassCTAButton, {
    pl: "2px",
    onClick: onLinkButtonClick
  }, "Link OnePass with Catch"))));
};