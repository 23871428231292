import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

/**
 * Delivery Estimate Widget
 */
import React from "react";
import { useDeliveryEstimateAnalytics, useDeliveryEstimatesImpressionAnalytics } from "@catchoftheday/analytics";
import { Box, ContentPlaceHolder, INLINE_ALERT_VARIANT_MAP } from "@catchoftheday/cg-components";
import { useDeliveryEstimateWidgetQuery } from "../../generated/graphql.jsx";
import { DeliveryEstimateError } from "../DeliveryEstimateError/index.js";
import { DeliveryEstimateMessage } from "../DeliveryEstimateMessage/index.js";
import { jsx as ___EmotionJSX } from "@emotion/react";
export function DeliveryEstimateWidget(_ref) {
  var _data$deliveryEstimat;

  var offerId = _ref.offerId,
      postcode = _ref.postcode,
      _ref$externalLoading = _ref.externalLoading,
      externalLoading = _ref$externalLoading === void 0 ? false : _ref$externalLoading;

  var _useDeliveryEstimateW = useDeliveryEstimateWidgetQuery({
    variables: {
      offerId: offerId,
      postcode: postcode
    }
  }),
      data = _useDeliveryEstimateW.data,
      queryError = _useDeliveryEstimateW.error,
      loading = _useDeliveryEstimateW.loading; // Has valid delivery estimate


  var deliveryEstimates = !!data && "deliveryEstimate" in data.deliveryEstimate ? (_data$deliveryEstimat = data.deliveryEstimate) === null || _data$deliveryEstimat === void 0 ? void 0 : _data$deliveryEstimat.deliveryEstimates : null; // Item cannot be delivery to the selected location

  var deliveryEstimateError = !!data && "error" in data.deliveryEstimate ? data.deliveryEstimate.error : null;
  useDeliveryEstimateAnalytics({
    enabled: !!deliveryEstimates && deliveryEstimates.length > 0,
    context: _objectSpread(_objectSpread({}, (deliveryEstimates === null || deliveryEstimates === void 0 ? void 0 : deliveryEstimates.find(function (estimate) {
      return estimate.deliveryType === "STANDARD";
    })) || {}), {}, {
      postcode: data === null || data === void 0 ? void 0 : data.deliveryEstimate.postcode,
      // Default to Standard as we only provide estimates for Standard
      deliveryType: "STANDARD",
      // Default to IN_STOCK as it can only differ in Cart
      parcelType: "IN_STOCK"
    }),
    options: {
      fireOnFallback: true
    }
  });

  var _useDeliveryEstimates = useDeliveryEstimatesImpressionAnalytics({
    enabled: !!deliveryEstimates && deliveryEstimates.length > 0,
    context: {
      labelType: "label",
      offerId: offerId,
      postcode: postcode
    }
  }),
      deliveryEstimateMessageRef = _useDeliveryEstimates.ref;

  if (externalLoading || loading) {
    return ___EmotionJSX(ContentPlaceHolder, {
      speed: 2,
      width: "100%",
      viewBox: "0 0 230 32",
      backgroundColor: "#f3f3f3",
      foregroundColor: "#ecebeb",
      "aria-label": "Loading"
    }, ___EmotionJSX("rect", {
      x: "0",
      y: "0",
      rx: "3",
      ry: "3",
      width: "100%",
      height: "32"
    }));
  }

  if (queryError) {
    return ___EmotionJSX(DeliveryEstimateError, null);
  }

  if (deliveryEstimateError) {
    return ___EmotionJSX(DeliveryEstimateError, {
      message: deliveryEstimateError.message
    });
  }

  if (!deliveryEstimates || deliveryEstimates.length === 0) {
    return null;
  }

  return ___EmotionJSX(Box, {
    borderRadius: 4,
    overflow: "hidden"
  }, !!deliveryEstimates && deliveryEstimates.length > 0 && deliveryEstimates.map(function (estimate, idx) {
    return ___EmotionJSX(DeliveryEstimateMessage, {
      key: "delivery-estimate-".concat(idx),
      ref: deliveryEstimateMessageRef,
      deliveryEstimate: estimate,
      deliveryType: estimate.deliveryType,
      containerProps: {
        borderRadius: 0,
        backgroundColor: INLINE_ALERT_VARIANT_MAP["info"].theme["light"].backgroundColor
      }
    });
  }));
}