import React, { useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Box, Flex, IconOnePass, Span } from "@catchoftheday/cg-components";
import { useTheme } from "@catchoftheday/theme";
import { AvailabilityTagsDocument, useAvailabilityTagsQuery } from "@product-app/generated/graphql";
import { AvailabilityTag } from "./AvailabilityTag";
import { jsx as ___EmotionJSX } from "@emotion/react";
export function AvailabilityTags(_ref) {
  var _data$productById, _data$productById$sel;

  var onePassExclusive = _ref.onePassExclusive,
      productId = _ref.productId,
      offerId = _ref.offerId;
  var theme = useTheme();
  var client = useApolloClient();

  var _useAvailabilityTagsQ = useAvailabilityTagsQuery({
    variables: {
      productId: productId,
      selectedOfferId: offerId
    },
    ssr: false
  }),
      data = _useAvailabilityTagsQ.data,
      error = _useAvailabilityTagsQ.error;

  var badges = data === null || data === void 0 ? void 0 : (_data$productById = data.productById) === null || _data$productById === void 0 ? void 0 : (_data$productById$sel = _data$productById.selectedOffer) === null || _data$productById$sel === void 0 ? void 0 : _data$productById$sel.badges;
  useEffect(function () {
    if (badges) {
      client.writeQuery({
        query: AvailabilityTagsDocument,
        data: data,
        variables: {
          productId: productId,
          selectedOfferId: offerId
        },
        id: "Badges:".concat(productId)
      });
    }
  }, [badges, client, productId, offerId, data]);

  if (!onePassExclusive && !(badges !== null && badges !== void 0 && badges.length) || error) {
    return null;
  }

  var shortDatedTag = (badges === null || badges === void 0 ? void 0 : badges.includes("short-dated")) && ___EmotionJSX(AvailabilityTag, {
    backgroundColor: theme.colors.secondary,
    position: "absolute",
    zIndex: 1,
    fontSize: "3xs",
    left: {
      xs: "5px",
      sm: "6px"
    },
    top: {
      xs: "5px",
      sm: "6px"
    }
  }, "Short dated");

  var memberExclusiveTag = onePassExclusive && ___EmotionJSX(AvailabilityTag, {
    borderRadius: "8px",
    fontFamily: "onePass",
    borderColor: "onePass.primary",
    borderStyle: "solid",
    borderWidth: "1px",
    bg: "white",
    color: "onePass.primary"
  }, ___EmotionJSX(IconOnePass, {
    color: "inherit",
    width: "54px",
    height: "12px",
    title: "OnePass",
    "aria-label": "OnePass",
    role: "img",
    mb: "1px",
    verticalAlign: "bottom"
  }), " ", ___EmotionJSX(Span, {
    lineHeight: "tight",
    fontSize: "xs",
    fontWeight: "bold",
    color: "onePass.textPrimary",
    textTransform: "none"
  }, "Exclusive"));

  var soldOutTag = (badges === null || badges === void 0 ? void 0 : badges.includes("sold-out")) && ___EmotionJSX(AvailabilityTag, {
    backgroundColor: theme.colors.error
  }, "Sold out");

  var yellowTag = badges !== null && badges !== void 0 && badges.includes("clearance") ? ___EmotionJSX(AvailabilityTag, {
    backgroundColor: theme.colors.tertiary
  }, "Clearance") : (badges === null || badges === void 0 ? void 0 : badges.includes("almost-gone")) && ___EmotionJSX(AvailabilityTag, {
    backgroundColor: theme.colors.tertiary
  }, "Almost gone");
  return ___EmotionJSX(Box, {
    className: "product-gallery-availability-tags"
  }, shortDatedTag, ___EmotionJSX(Flex, {
    position: "absolute",
    zIndex: 1,
    textAlign: "right",
    fontSize: "2xs",
    right: {
      xs: "10px",
      sm: "12px"
    },
    bottom: {
      xs: "5px",
      sm: "6px"
    },
    width: "100%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "flex-end"
  }, ___EmotionJSX(Box, null, memberExclusiveTag, soldOutTag || yellowTag)));
}