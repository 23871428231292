import { createSvgIconComponent } from "../utils";
export var IconAfterPayBlack = createSvgIconComponent({
  viewBox: "0 0 140 29",
  paths: [{
    d: "M68.62 14.61a3.43 3.43 0 10-3.43 3.52 3.51 3.51 0 003.43-3.52zM70.1 9.7a7 7 0 010 9.82 6.87 6.87 0 01-4.91 2 4.77 4.77 0 01-3.29-1.31l-.14-.11v7.78L58.24 29V8h3.52v1l.14-.14a4.39 4.39 0 013.29-1.17A7 7 0 0170.1 9.7z"
  }, {
    d: "M76.9 14.61a3.43 3.43 0 103.43-3.52 3.51 3.51 0 00-3.43 3.52zm-1.48 4.91a6.93 6.93 0 010-9.82 6.87 6.87 0 014.91-2A4.75 4.75 0 0183.61 9l.15.12V8h3.52v13.27h-3.52v-1.05l-.15.15a4.38 4.38 0 01-3.28 1.16 7 7 0 01-4.91-2.01z"
  }, {
    d: "M90.79 29l3.17-7.7-5.32-13.34h3.9l3.31 8.28 3.37-8.28h3.87L94.6 29h-3.81"
  }, {
    d: "M6.8 9.94a4.68 4.68 0 104.65 4.67A4.66 4.66 0 006.8 9.94zm0 11.45a6.86 6.86 0 01-4.82-2A6.84 6.84 0 012 9.79a6.81 6.81 0 014.8-1.95 7 7 0 014.37 1.79l.28.23V8.13h2.16v13h-2.16v-1.77l-.28.24a7 7 0 01-4.37 1.79z"
  }, {
    d: "M17.51 21.1V10.17h-2v-2h2V4.29A4.18 4.18 0 0121.76.1h2.71l-.54 2.05h-2.11a2.24 2.24 0 00-2.15 2.2v3.78h4.1v2h-4.1V21.1h-2.16"
  }, {
    d: "M31.9 21.1a4.25 4.25 0 01-4.24-4.25v-6.68h-2v-2h2V.1h2.15v8h4.1v2h-4.1v6.62A2.3 2.3 0 0032 19.06h2.11l.55 2H31.9"
  }, {
    d: "M41.19 10a4.26 4.26 0 00-3.85 3l-.12.25h8L45 13a4.2 4.2 0 00-3.81-3zm0 11.31a5.75 5.75 0 01-4.37-2 6.62 6.62 0 01-1.76-4.08 4.09 4.09 0 010-.6 7.25 7.25 0 01.17-1.53 6.8 6.8 0 011.59-3.1 5.91 5.91 0 014.37-2 6 6 0 014.41 2 6.64 6.64 0 011.62 3.14 8.46 8.46 0 01.15 1.92H37.1v.23a4.42 4.42 0 004 4.07A5.28 5.28 0 0044.42 18l1.8 1.08a7.86 7.86 0 01-1.6 1.3 7.32 7.32 0 01-3.43.95z"
  }, {
    d: "M49.49 21.1v-13h2.16v1.67l.3-.33c.76-.85 3-1.54 4.5-1.6L55.93 10a4.29 4.29 0 00-4.28 4.18v6.92h-2.16"
  }, {
    d: "M121.71 11.63l3.78-2.18c-.42-.74-.31-.56-.69-1.25s-.26-1 .58-1h7.27c.72 0 .89.31.53.94q-1.8 3.17-3.63 6.31c-.39.66-.74.66-1.14 0s-.31-.51-.76-1.27l-3.77 2.19a3 3 0 00.25.45c.93 1.62 1.35 2.4 2.31 4a2.88 2.88 0 004.9.26 4.44 4.44 0 00.41-.62q3.44-5.94 6.85-11.89a4.71 4.71 0 00.54-1.29 2.85 2.85 0 00-2.79-3.48h-14.7a2.88 2.88 0 00-2.6 4.2c.32.64.7 1.25 1.06 1.87l1.6 2.75",
    props: {
      fill: "#000000"
    }
  }, {
    d: "M110.82 23.87v-4.35h-1.41c-.83 0-1-.3-.6-1 1.2-2.09 2.4-4.17 3.62-6.24.36-.62.68-.67 1.08 0 1.2 2.08 2.41 4.17 3.6 6.26.38.67.2 1-.56 1h-1.46v4.36h5.1a2.85 2.85 0 002.66-4.07 5.72 5.72 0 00-.32-.66l-6.79-11.8a4.52 4.52 0 00-.84-1.1 2.82 2.82 0 00-4.38.65c-2.46 4.2-4.91 8.4-7.31 12.63a2.85 2.85 0 002.33 4.32h5.29",
    props: {
      fill: "#000000"
    }
  }]
});