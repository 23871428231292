import { createSvgIconComponent } from "../utils";
export var IconPaypalGrey = createSvgIconComponent({
  viewBox: "0 0 132 32",
  paths: [{
    d: "M127.34 7.667l-2.977 18.935a.604.604 0 0 0 .597.699h2.994c.495 0 .917-.361.995-.85l2.934-18.596a.604.604 0 0 0-.597-.7h-3.35a.605.605 0 0 0-.597.512m-8.932 12.956c-.336 1.985-1.91 3.317-3.92 3.317-1.007 0-1.813-.324-2.332-.938-.513-.608-.707-1.474-.544-2.44.313-1.965 1.913-3.34 3.892-3.34.986 0 1.787.327 2.315.946.532.623.74 1.495.589 2.455zm4.84-6.759h-3.473a.604.604 0 0 0-.598.51l-.152.972-.243-.352c-.752-1.092-2.428-1.457-4.102-1.457-3.836 0-7.114 2.908-7.751 6.986-.332 2.034.139 3.978 1.292 5.334 1.06 1.247 2.572 1.766 4.374 1.766 3.092 0 4.807-1.987 4.807-1.987l-.154.966a.604.604 0 0 0 .597.699h3.127c.496 0 .918-.36.995-.85l1.877-11.888a.604.604 0 0 0-.597-.699zm-20.84.083c-.396 2.604-2.384 2.604-4.307 2.604h-1.094l.767-4.86c.047-.294.3-.51.597-.51h.502c1.309 0 2.545 0 3.183.745.381.446.497 1.107.352 2.021zm-.837-6.791h-7.252c-.496 0-.918.36-.995.85l-2.932 18.596a.604.604 0 0 0 .597.699h3.72a.705.705 0 0 0 .697-.596l.832-5.271c.077-.49.5-.851.995-.851h2.295c4.777 0 7.534-2.312 8.254-6.894.324-2.004.013-3.578-.925-4.68-1.031-1.212-2.86-1.853-5.286-1.853z",
    props: {
      fill: "grey"
    }
  }, {
    d: "M90.017 13.864h-3.49c-.335 0-.647.166-.834.442l-4.815 7.091-2.04-6.814a1.007 1.007 0 0 0-.966-.719h-3.43a.605.605 0 0 0-.573.8l3.843 11.281-3.615 5.101c-.284.4.003.954.494.954h3.487c.33 0 .64-.162.828-.434l11.608-16.753a.605.605 0 0 0-.497-.949m-23.335 6.76c-.335 1.984-1.91 3.316-3.92 3.316-1.007 0-1.813-.324-2.331-.938-.514-.608-.707-1.474-.545-2.44.313-1.965 1.914-3.34 3.892-3.34.986 0 1.787.327 2.315.946.532.623.741 1.495.589 2.455zm4.84-6.76h-3.473a.604.604 0 0 0-.597.51l-.153.972-.243-.352c-.752-1.092-2.428-1.457-4.101-1.457-3.837 0-7.114 2.908-7.752 6.986-.332 2.034.14 3.978 1.293 5.334 1.06 1.247 2.572 1.766 4.373 1.766 3.093 0 4.808-1.987 4.808-1.987l-.155.966a.604.604 0 0 0 .597.699h3.127c.496 0 .918-.36.995-.85l1.878-11.888a.604.604 0 0 0-.598-.699zm-20.84.083c-.396 2.604-2.384 2.604-4.307 2.604h-1.094l.767-4.86c.047-.294.3-.51.597-.51h.502c1.309 0 2.546 0 3.183.745.382.446.497 1.107.353 2.021zm-.836-6.791h-7.253c-.495 0-.917.36-.995.85l-2.932 18.596a.605.605 0 0 0 .597.699h3.462c.496 0 .919-.361.996-.85l.791-5.017c.077-.49.5-.851.995-.851h2.295c4.777 0 7.534-2.312 8.255-6.894.324-2.004.013-3.578-.926-4.68-1.031-1.212-2.86-1.853-5.285-1.853zM6.998 28.424l1.49-9.448c.11-.692.7-1.201 1.4-1.201h2.92c5.73 0 10.218-2.329 11.53-9.063.037-.19.068-.374.096-.555l-.007-.005-.013-.007V8.12c.008.005.017.007.025.01.39-2.488-.003-4.182-1.35-5.716C21.608.723 18.93 0 15.504 0H5.557c-.7 0-1.296.51-1.405 1.201L.011 27.463a.853.853 0 0 0 .843.986h6.14l.004-.025z",
    props: {
      fill: "black"
    }
  }, {
    d: "M24.439 8.13c-.03.19-.063.383-.102.582-1.311 6.734-5.799 9.063-11.53 9.063H9.89c-.7 0-1.292.51-1.4 1.201l-1.92 12.16a.747.747 0 0 0 .738.864h5.175c.613 0 1.134-.446 1.23-1.05l.05-.264.975-6.182.063-.342a1.245 1.245 0 0 1 1.23-1.05h.774c5.014 0 8.94-2.038 10.087-7.929.479-2.461.231-4.516-1.036-5.96a4.93 4.93 0 0 0-1.417-1.093",
    props: {
      fill: "grey"
    }
  }, {
    d: "M23.066 7.583a9.527 9.527 0 0 0-.619-.159 11.327 11.327 0 0 0-.656-.125 16.265 16.265 0 0 0-2.572-.187h-7.796a1.244 1.244 0 0 0-1.23 1.051L8.536 18.669l-.048.307c.11-.692.7-1.201 1.4-1.201h2.92c5.73 0 10.218-2.329 11.53-9.063.038-.199.071-.392.1-.582a7.048 7.048 0 0 0-1.372-.547",
    props: {
      fill: "black"
    }
  }]
});