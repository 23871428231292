import React from "react";
import { Alert, Box, Heading } from "@catchoftheday/cg-components";
import { DEFAULT_DELIVERY_ESTIMATE_ERROR_MESSAGE } from "../../consts/index.js";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var DeliveryEstimateError = function DeliveryEstimateError(_ref) {
  var message = _ref.message;
  return ___EmotionJSX(Box, {
    borderRadius: 4,
    overflow: "hidden"
  }, ___EmotionJSX(Alert, {
    type: "inline",
    variant: "warning",
    alignIcon: "flex-start"
  }, ___EmotionJSX(Heading.h5, {
    m: 0
  }, message || DEFAULT_DELIVERY_ESTIMATE_ERROR_MESSAGE)));
};