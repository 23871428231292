import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["averageRating", "reviewCount", "summary", "setSelectedTab"];
import React from "react";
import { Box, Flex, Span, StarRating, Text, TextButton } from "@catchoftheday/cg-components";
import { useTheme } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ReviewStatistics = function ReviewStatistics(_ref) {
  var averageRating = _ref.averageRating,
      reviewCount = _ref.reviewCount,
      summary = _ref.summary,
      _setSelectedTab = _ref.setSelectedTab,
      boxProps = _objectWithoutProperties(_ref, _excluded);

  var _useTheme = useTheme(),
      backgroundColor = _useTheme.colors.ui.backgroundColor;

  return ___EmotionJSX(Box, _extends({
    border: "1px solid",
    borderColor: "lightGrey",
    position: "relative",
    borderRadius: "4px"
  }, boxProps), averageRating && ___EmotionJSX(Flex, {
    position: "absolute",
    top: "0",
    left: "50%",
    size: "84px",
    borderRadius: "50%",
    bg: "secondary",
    justifyContent: "center",
    alignItems: "center",
    style: {
      transform: "translate(-50%, -50%)"
    }
  }, ___EmotionJSX(Text, {
    color: "white",
    fontSize: "30px",
    fontWeight: "normal"
  }, averageRating.toFixed(1))), ___EmotionJSX(Flex, {
    justifyContent: "center",
    alignItems: "center",
    mt: "56px"
  }, averageRating && ___EmotionJSX(StarRating, {
    rating: averageRating,
    backgroundColour: backgroundColor
  }), ___EmotionJSX(TextButton, {
    ml: 3,
    onClick: function onClick() {
      return _setSelectedTab(5);
    },
    fontWeight: "light"
  }, "(", reviewCount, " ", reviewCount === 1 ? "Review" : "Reviews", ")")), ___EmotionJSX(Box, {
    mb: 3
  }, summary.map(function (review, index) {
    return ___EmotionJSX(StatRow, {
      key: review.rating,
      label: "".concat(review.rating, " Star"),
      count: review.total,
      percentage: review.percentage,
      setSelectedTab: function setSelectedTab() {
        return _setSelectedTab(index);
      }
    });
  })));
};

var StatRow = function StatRow(_ref2) {
  var label = _ref2.label,
      count = _ref2.count,
      percentage = _ref2.percentage,
      setSelectedTab = _ref2.setSelectedTab;
  var theme = useTheme();
  var percent = "".concat(percentage, "%");
  return ___EmotionJSX(Flex, {
    alignItems: "center"
  }, ___EmotionJSX(Text, {
    width: {
      xs: "50px",
      lg: "100px"
    },
    mr: 4,
    textAlign: "right"
  }, label), ___EmotionJSX(Box, {
    height: "32px",
    background: "linear-gradient(to right, ".concat(theme.colors.gold, " ").concat(percent, ", ").concat(theme.colors.grey, " ").concat(percent, ")"),
    flexGrow: 1
  }), count > 0 ? ___EmotionJSX(Box, {
    width: {
      xs: "50px",
      lg: "100px"
    },
    ml: 4
  }, ___EmotionJSX(TextButton, {
    onClick: setSelectedTab,
    fontWeight: "light"
  }, count)) : ___EmotionJSX(Span, {
    width: {
      xs: "50px",
      lg: "100px"
    },
    ml: 4
  }, "0"));
};