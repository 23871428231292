import { theme } from "@catchoftheday/theme";
import { centsToDollars } from "@catchoftheday/utilities";
// Wrapper around the dataLayer object for google analytics and related tools
var dataLayer = {
  push: function push(data) {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push(data);
  }
};
export function updateDataLayer(product, customerId) {
  var _product$selectedOffe, _product$selectedOffe2, _product$brand, _product$category;

  var price = (_product$selectedOffe = product.selectedOffer) === null || _product$selectedOffe === void 0 ? void 0 : (_product$selectedOffe2 = _product$selectedOffe.priceTag) === null || _product$selectedOffe2 === void 0 ? void 0 : _product$selectedOffe2.priceLabel.price.amount;
  dataLayer.push({
    ecomm_pagetype: "product",
    ecomm_totalvalue: price ? centsToDollars(price) : undefined,
    ecomm_prodid: [product.id],
    CustomerID: customerId || "",
    SiteType: window.matchMedia("(max-width: ".concat(theme.breakpoints.md, ")")).matches ? "m" : "d",
    PageType: "Product",
    ProductID: product.id,
    brand: ((_product$brand = product.brand) === null || _product$brand === void 0 ? void 0 : _product$brand.name) || "",
    category: ((_product$category = product.category) === null || _product$category === void 0 ? void 0 : _product$category.name) || "",
    isAdult: product.adult === true ? "1" : "0"
  });
} // Updates window.ecommerceData.products

export function updateEcommerceData(product, sourceType) {
  if (true) {
    var _product$selectedOffe3, _product$selectedOffe4, _product$category2, _product$brand2, _product$selectedOffe5, _product$selectedOffe6, _product$selectedOffe7;

    if (!window.ecommerceData) {
      window.ecommerceData = {};
    }

    var price = (_product$selectedOffe3 = product.selectedOffer) === null || _product$selectedOffe3 === void 0 ? void 0 : (_product$selectedOffe4 = _product$selectedOffe3.priceTag) === null || _product$selectedOffe4 === void 0 ? void 0 : _product$selectedOffe4.priceLabel.price.amount;
    window.ecommerceData.products = [{
      price: price ? centsToDollars(price) : undefined,
      category: ((_product$category2 = product.category) === null || _product$category2 === void 0 ? void 0 : _product$category2.name) || "",
      list: sourceType || 0,
      brand: ((_product$brand2 = product.brand) === null || _product$brand2 === void 0 ? void 0 : _product$brand2.name) || "",
      clubCatchExclusive: !!((_product$selectedOffe5 = product.selectedOffer) !== null && _product$selectedOffe5 !== void 0 && _product$selectedOffe5.clubExclusive),
      dualPricing: !!((_product$selectedOffe6 = product.selectedOffer) !== null && _product$selectedOffe6 !== void 0 && (_product$selectedOffe7 = _product$selectedOffe6.priceTag) !== null && _product$selectedOffe7 !== void 0 && _product$selectedOffe7.clubSellPrice),
      id: product.id,
      name: product.title,
      position: 1,
      quantity: "1"
    }];
  }
}