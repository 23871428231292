import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useCallback, useState } from "react";
import { Box, Carousel, Flex, Icon360, IconPlay, Image, isOrbitvu, isYoutube, Lightbox, LoadingSpinner, Text } from "@catchoftheday/cg-components";
import { styled, themeGet, useMedia, useTheme } from "@catchoftheday/theme";
import { resizeImage } from "@catchoftheday/utilities";
import { AvailabilityTags } from "@product-app/components/AvailabilityTags";
import { jsx as ___EmotionJSX } from "@emotion/react";
var Thumbnail = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  &:hover {\n    cursor: pointer;\n    border-color: ", "}\n  }\n"])), themeGet("colors.brand.primary"));
export var thumbnailSize = "_w143h117";
export var previewSize = "_w803h620";
export var lightboxSize = "_w1320h1080";
export var Gallery = function Gallery(_ref) {
  var _ref$items = _ref.items,
      items = _ref$items === void 0 ? [] : _ref$items,
      productId = _ref.productId,
      offerId = _ref.offerId,
      onePassExclusive = _ref.onePassExclusive;

  var _useState = useState(items[0]),
      previewItem = _useState[0],
      _setPreviewItem = _useState[1];

  var _useState2 = useState(false),
      isPreviewLoading = _useState2[0],
      setIsPreviewLoading = _useState2[1];

  var _useState3 = useState(false),
      autoPlay = _useState3[0],
      setAutoPlay = _useState3[1];

  var _useState4 = useState(false),
      showLightbox = _useState4[0],
      setShowLightbox = _useState4[1];

  var openLightbox = useCallback(function () {
    return setShowLightbox(true);
  }, []);
  var closeLightbox = useCallback(function () {
    return setShowLightbox(false);
  }, []);

  function setPreviewItem(item) {
    _setPreviewItem(function (prev) {
      if (prev.previewUrl !== item.previewUrl) {
        setIsPreviewLoading(true);
      }

      return item;
    });
  }

  var _useTheme = useTheme(),
      breakpoints = _useTheme.breakpoints,
      colors = _useTheme.colors;

  var isDesktop = useMedia(["(min-width: ".concat(breakpoints.md, ")")], [true]);

  if (items.length === 0) {
    return null;
  }

  var commonButtonStyles = {
    background: "white",
    border: "solid 1px",
    borderColor: colors.typography.inactive,
    borderRadius: 0,
    height: isDesktop ? "25px" : "100%",
    width: isDesktop ? "100%" : "25px"
  };
  var slides = items.map(function (item, index) {
    return ___EmotionJSX(Box, {
      key: item.src,
      py: {
        xs: "0px",
        md: "6px",
        lg: "5px"
      },
      px: {
        xs: "5px",
        md: "0px"
      },
      tabIndex: index + 1,
      onKeyDown: function onKeyDown(e) {
        return e.key == "Enter" && setPreviewItem(item);
      }
    }, ___EmotionJSX(Thumbnail, {
      border: "solid 1px",
      borderColor: "ui.borderColor",
      onClick: openLightbox,
      mr: "1px"
    }, ___EmotionJSX(Flex, {
      position: "relative"
    }, ___EmotionJSX(Image, {
      src: resizeImage(item.previewUrl, thumbnailSize),
      alt: item.altText,
      onMouseEnter: function onMouseEnter() {
        setPreviewItem(item);
        setAutoPlay(false);
      },
      onClick: function onClick() {
        setPreviewItem(item);
      },
      onMouseLeave: function onMouseLeave() {
        return setAutoPlay(true);
      }
    }), item.type !== "image" && ___EmotionJSX(Flex, {
      style: {
        pointerEvents: "none"
      },
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "rgba(0,0,0,.45)"
    }, item.type === "orbitvu" ? ___EmotionJSX(Icon360, {
      fill: colors.textWhite,
      minWidth: "70px"
    }) : item.type === "youtube" ? ___EmotionJSX(IconPlay, {
      fill: colors.textWhite
    }) : null), ___EmotionJSX("link", {
      href: resizeImage(item.previewUrl, thumbnailSize),
      itemProp: "image"
    }))));
  });
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Lightbox, {
    open: showLightbox,
    onClose: closeLightbox,
    sourceIndex: previewItem.index,
    sources: items.map(function (item) {
      return !isOrbitvu(item.src) && !isYoutube(item.src) ? resizeImage(item.src, lightboxSize) : item.src;
    })
  }), ___EmotionJSX(Flex, {
    flexDirection: {
      xs: "column-reverse",
      md: "initial"
    }
  }, ___EmotionJSX(Carousel, {
    carouselName: "Product gallery",
    slides: slides,
    isVertical: isDesktop,
    slidesPerBreakpoint: [6, 6, 6, 4, 4],
    containerProps: {
      width: {
        xs: "100%",
        md: "100px",
        lg: "85px"
      },
      minWidth: {
        xs: "auto",
        md: "100px",
        lg: "85px"
      },
      mt: {
        md: slides.length > 6 ? "0px" : "-5px"
      }
    },
    slideWidth: 80,
    slideHeight: 75,
    autoPlay: autoPlay,
    step: 1,
    previousButtonStyles: _objectSpread(_objectSpread({}, commonButtonStyles), {}, {
      top: 0
    }),
    nextButtonStyles: _objectSpread(_objectSpread({}, commonButtonStyles), {}, {
      bottom: 0,
      top: isDesktop ? "auto" : "0"
    })
  }), ___EmotionJSX(Flex, {
    border: "solid 1px",
    borderColor: "ui.borderColor",
    m: {
      xs: "0 0 10px",
      md: "0 0 0 10px"
    },
    position: "relative",
    onClick: openLightbox,
    width: "100%",
    cursor: "zoom-in",
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center"
  }, ___EmotionJSX(Image, {
    alt: previewItem === null || previewItem === void 0 ? void 0 : previewItem.altText,
    src: resizeImage(previewItem.previewUrl, previewSize),
    title: "Preview image",
    onLoad: function onLoad() {
      return setIsPreviewLoading(false);
    },
    maxWidth: {
      xs: "803px",
      lg: "auto"
    },
    maxHeight: {
      xs: "620px",
      lg: "auto"
    }
  }), ___EmotionJSX(AvailabilityTags, {
    productId: productId,
    offerId: offerId,
    onePassExclusive: onePassExclusive
  }), previewItem.type === "youtube" || previewItem.type === "orbitvu" ? ___EmotionJSX(Flex, {
    position: "absolute",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0,0,0,.35)"
  }, ___EmotionJSX(Flex, {
    flexDirection: "column",
    alignItems: "center"
  }, previewItem.type === "orbitvu" ? ___EmotionJSX(Icon360, {
    fill: colors.textWhite,
    height: "auto",
    width: "100%",
    maxWidth: "180px"
  }) : previewItem.type === "youtube" ? ___EmotionJSX(IconPlay, {
    fill: colors.textWhite,
    height: "auto",
    width: "100%",
    maxWidth: "180px"
  }) : null, ___EmotionJSX(Text, {
    color: colors.textWhite,
    fontSize: "1rem"
  }, "Click to view"))) : null, isPreviewLoading && ___EmotionJSX(LoadingSpinner, {
    hasOverlay: true,
    "aria-label": "Loading image"
  }))));
};