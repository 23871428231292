import React from "react";
import { Box, Button, Text } from "@catchoftheday/cg-components";
import { GENERIC_ERROR_MESSAGE } from "@product-app/consts";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ErrorContainer = function ErrorContainer(_ref) {
  var retry = _ref.retry,
      message = _ref.message;
  return ___EmotionJSX(Box, {
    textAlign: "center",
    py: "20px"
  }, ___EmotionJSX(Text, null, message || GENERIC_ERROR_MESSAGE), ___EmotionJSX(Button, {
    onClick: retry || location.reload,
    outline: true,
    variant: "secondary",
    sizeVariant: "sm"
  }, "Retry"));
};