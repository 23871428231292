import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Icon } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Icon360 = function Icon360(props) {
  return ___EmotionJSX(Icon, _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 342.67 196.57",
    fill: "currentColor"
  }, props), ___EmotionJSX("path", {
    d: "M189,247.27q-15.9,12.1-31.85,24.13a17.37,17.37,0,0,1-2.57,1.34,16,16,0,0,1-.72-3.14c-.09-3.66-.09-7.33,0-11,0-1.87-.7-2.57-2.61-2.74-9.44-.85-18.89-1.62-28.27-2.85-28.85-3.77-56.92-10.35-82.92-24-8.88-4.66-17.2-10.16-24.17-17.48C7.49,202.71,2.52,192.6,3.9,180a34.61,34.61,0,0,1,5.54-14.81c6.47-10.29,16-17.28,26.25-23.31.88-.52,1.82-1,2.43.35s2.07,2.54.21,3.9c-2.55,1.87-5.22,3.58-7.7,5.54-6,4.71-10.88,10.26-13.44,17.6-2.65,7.63-.51,14.43,3.74,20.78,4.44,6.65,10.69,11.35,17.35,15.54,12.95,8.13,27.16,13.28,41.78,17.39A299.22,299.22,0,0,0,137.9,233.1c3,.24,6.06.66,9.09,1a4.73,4.73,0,0,0,.65,0c7.23.05,6.11.27,6.2-6.34,0-2.67,0-5.33,0-8,0-1.65.74-2.11,2.14-1.05q6.38,4.81,12.75,9.62c6.77,5.13,13.56,10.24,20.29,15.41C191.24,245.45,191.22,245.6,189,247.27Z",
    transform: "translate(-3.67 -76.17)"
  }), ___EmotionJSX("path", {
    d: "M346.13,188.06c-.81,7.55-4.13,14-8.78,19.78-5.69,7.11-12.83,12.53-20.54,17.25-17.51,10.72-36.7,17.15-56.51,21.91a313.07,313.07,0,0,1-55.15,8.19c-2.92.18-5.85.13-8.77.18-.13-3-.27-5.93-.4-8.89,0-.5,0-1,0-1.5h0c0-2.83,0-5.66,0-8.49,0-1.63.62-2.32,2.34-2.44,8.61-.64,17.26-1.11,25.83-2.17,26.66-3.28,52.77-8.85,77.19-20.57,9.8-4.7,19-10.38,26-19a36.68,36.68,0,0,0,4.67-7.61c3.18-6.84,2.46-13.58-1.18-20.08-3.75-6.7-9.26-11.7-15.48-16-1.23-.85-2.53-1.61-3.71-2.54-1.79-1.41-.23-2.66.34-3.91.65-1.43,1.64-.71,2.47-.22,9.39,5.52,18.08,11.87,24.55,20.84C344.42,170.32,347.15,178.64,346.13,188.06Z",
    transform: "translate(-3.67 -76.17)"
  }), ___EmotionJSX("path", {
    d: "M273.86,117.78a37,37,0,0,0-2.06-7.18c-6.71-17.41-21.64-24.24-38-19.43-7.87,2.31-13.16,7.88-17.23,15-6.57,11.5-6.55,37-.12,48.42,3.86,6.83,9.06,11.79,16.53,14.34,4.53,1.54,9.18,1.54,13.83,1.21,9.47-.67,16.68-5.17,21.7-13.18,5.14-8.21,6.31-17.38,6.54-28.71C274.85,126,274.53,121.87,273.86,117.78Zm-18.7,25.67a20.29,20.29,0,0,1-3.89,7.41c-4.29,5.12-11.12,5.13-15.79.38-3.72-3.78-4.77-8.67-5.38-13.64a60.18,60.18,0,0,1,0-7.48c-.28-5.19,0-10.31,2-15.21,2.52-6.22,6.59-9.61,11.55-9.52s9,3.63,11.09,10A48.2,48.2,0,0,1,255.16,143.45Z",
    transform: "translate(-3.67 -76.17)"
  }), ___EmotionJSX("path", {
    d: "M199,139.69c-1.92-15.78-15.27-23.85-25.71-22.94-.84.07-1.69,0-3.25,0L192,91c-7.38,0-13.85,0-20.33,0A4.42,4.42,0,0,0,168,93c-2.5,3.1-5.16,6.08-7.69,9.17-5.59,6.81-11.53,13.33-16,21a39.79,39.79,0,0,0-5.27,21,23.19,23.19,0,0,0,6.57,16.28c9.4,9.64,21,11.42,33.59,8.89A25.5,25.5,0,0,0,191.47,163C198.37,156.64,200,148.47,199,139.69Zm-29.89,16c-6.35-.09-11.14-5.12-11.13-11.68,0-6.74,4.95-11.89,11.48-11.94a11.81,11.81,0,1,1-.35,23.62Z",
    transform: "translate(-3.67 -76.17)"
  }), ___EmotionJSX("path", {
    d: "M117.5,164.43c-5.53,4.13-11.92,6-21,5.88a29.42,29.42,0,0,1-18.8-6.2,27.65,27.65,0,0,1-8.13-10.57c-.79-1.69-.57-2.72,1.24-3.55q6.12-2.81,12.1-5.89c1.9-1,2.55-.41,3.1,1.52a15.12,15.12,0,0,0,2.46,4.85c3.87,5.13,12.07,6.11,17,2a9.67,9.67,0,0,0,3.32-9.9,10.56,10.56,0,0,0-7.54-8.52,15.81,15.81,0,0,0-3.43-.61c-2.83-.22-3-.2-3.11-2.66,0-.7,0-1.84,0-2.53.1-1.82-.32-4,.55-5.34.61-.93,3-.66,4.61-.94,4.57-.79,8.3-5.7,7.73-10.2s-5.71-8.28-10.73-7.62a11.34,11.34,0,0,0-9,6.34c-1.58,3-1.7,3-4.81,1.55-2.72-1.25-5.42-2.55-8.06-4-2.4-1.28-2.62-1.91-1.48-4.33A23.07,23.07,0,0,1,91.06,90.32c7.45-1.33,14.9-1,21.83,2.86,12.22,6.84,14.48,23.1,6.62,31.7-1.47,1.62-1.6,2.59.24,3.91C129.69,135.91,130.16,155,117.5,164.43Z",
    transform: "translate(-3.67 -76.17)"
  }), ___EmotionJSX("path", {
    d: "M298.49,76.17c-10.24.2-15,8.06-14.87,14.78.14,8.77,6.29,15.12,15,15.08,8.4,0,14.64-6.38,14.66-14.91A14.66,14.66,0,0,0,298.49,76.17Zm.14,23.67a9,9,0,0,1-8.71-8.49,8.61,8.61,0,0,1,8.32-8.75,8.72,8.72,0,0,1,8.7,8.39A8.9,8.9,0,0,1,298.63,99.84Z",
    transform: "translate(-3.67 -76.17)"
  }));
};