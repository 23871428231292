export var formatLocation = function formatLocation(_ref) {
  var _state$toUpperCase;

  var suburb = _ref.suburb,
      state = _ref.state,
      postcode = _ref.postcode;

  var _state = (_state$toUpperCase = state === null || state === void 0 ? void 0 : state.toUpperCase()) !== null && _state$toUpperCase !== void 0 ? _state$toUpperCase : "";

  var _stateAndPostcode = "".concat(_state !== "NZ" ? _state : "", " ").concat(postcode).trim();

  return "".concat(formatSuburb(suburb), ", ").concat(_stateAndPostcode);
};
var splitRegex = /[-'’ ](?!\w )/g;
/**
 * Formats a suburb string to title case following Australian suburb naming conventions. Also considers accents in many NZ suburbs.
 * @see https://www.stylemanual.gov.au/grammar-punctuation-and-conventions/names-and-terms/australian-place-names
 * @param suburb
 */

var formatSuburb = function formatSuburb(suburb) {
  var lowercaseSuburb = suburb.toLowerCase();
  splitRegex.lastIndex = 0; // Reset Regex.

  function next(indices) {
    var _splitRegex$exec;

    var index = (_splitRegex$exec = splitRegex.exec(lowercaseSuburb)) === null || _splitRegex$exec === void 0 ? void 0 : _splitRegex$exec.index;
    if (index === undefined) return indices;
    indices.push(index + 1);
    return next(indices);
  }

  var indices = next([0]); // [0] is the first letter.

  var firstPass = indices.reduce(function (acc, letterIndex, index) {
    var nextLetterIndex = letterIndex + 1;
    var letter = lowercaseSuburb.slice(acc.length, nextLetterIndex);
    var groupEndIndex = indices[index + 1];
    var rest = lowercaseSuburb.slice(nextLetterIndex, groupEndIndex);
    return "".concat(acc).concat(letter.toUpperCase()).concat(rest);
  }, ""); // Support is negative-look behind isn't widespread enough, so we'll just do a second pass manually.

  var secondPassParts = firstPass.split(/mc/i); // Join parts upper-casing first letter of each part.

  return secondPassParts.reduce(function (acc, part) {
    var firstLetter = part[0];
    var rest = part.slice(1); // First letter could be an empty string here e.g. with suburb CANBERRA MC

    return "".concat(acc, "Mc").concat((firstLetter === null || firstLetter === void 0 ? void 0 : firstLetter.toUpperCase()) || "").concat(rest);
  });
};