import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// @ts-ignore

/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
var defaultOptions = {};
export var DeliveryEstimateWidgetDocument = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query DeliveryEstimateWidget($offerId: ID!, $postcode: String!) {\n  deliveryEstimate(offerId: $offerId, postcode: $postcode) {\n    ... on DeliveryEstimateOfferSuccessResponse {\n      offerId\n      postcode\n      deliveryEstimate {\n        label\n        labelType\n        labelDescription\n        shortLabel\n        minRange\n        maxRange\n        timeStamp\n        confidence\n        deliveryType\n      }\n      deliveryEstimates {\n        label\n        labelType\n        labelDescription\n        shortLabel\n        minRange\n        maxRange\n        timeStamp\n        confidence\n        deliveryType\n      }\n    }\n    ... on DeliveryEstimateOfferErrorResponse {\n      offerId\n      postcode\n      error {\n        code\n        message\n      }\n    }\n  }\n}\n    "])));
/**
 * __useDeliveryEstimateWidgetQuery__
 *
 * To run a query within a React component, call `useDeliveryEstimateWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryEstimateWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryEstimateWidgetQuery({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      postcode: // value for 'postcode'
 *   },
 * });
 */

export function useDeliveryEstimateWidgetQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(DeliveryEstimateWidgetDocument, options);
}
export function useDeliveryEstimateWidgetLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(DeliveryEstimateWidgetDocument, options);
}
export var LocationSuggestionsDocument = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query LocationSuggestions($searchTerm: String, $postcode: String, $suburb: String) {\n  locationSuggestions(\n    input: {postcode: $postcode, suburb: $suburb, searchParameters: {searchTerm: $searchTerm}}\n  ) {\n    postcodes {\n      id\n      postcode\n      suburb\n      state\n      stateCode\n      country\n    }\n  }\n}\n    "])));
/**
 * __useLocationSuggestionsQuery__
 *
 * To run a query within a React component, call `useLocationSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationSuggestionsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      postcode: // value for 'postcode'
 *      suburb: // value for 'suburb'
 *   },
 * });
 */

export function useLocationSuggestionsQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(LocationSuggestionsDocument, options);
}
export function useLocationSuggestionsLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(LocationSuggestionsDocument, options);
}
export var UserLocationDocument = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    query UserLocation($customerId: ID, $deviceId: String) {\n  userLocation(customerId: $customerId, deviceId: $deviceId) {\n    ... on UserLocationErrorResponse {\n      customerId\n      deviceId\n      error {\n        message\n      }\n    }\n    ... on UserLocationSuccessResponse {\n      customerId\n      deviceId\n      location {\n        id\n        postcode\n        suburb\n        state\n        country\n        geolocation {\n          latitude\n          longitude\n        }\n      }\n    }\n  }\n}\n    "])));
/**
 * __useUserLocationQuery__
 *
 * To run a query within a React component, call `useUserLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLocationQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */

export function useUserLocationQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useQuery(UserLocationDocument, options);
}
export function useUserLocationLazyQuery(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useLazyQuery(UserLocationDocument, options);
}
export var UpdateUserLocationDocument = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    mutation UpdateUserLocation($input: UpdateUserLocationInput!) {\n  updateUserLocation(input: $input) {\n    ... on UpdateUserLocationErrorResponse {\n      error {\n        message\n      }\n    }\n    ... on UpdateUserLocationSuccessResponse {\n      location {\n        id\n        postcode\n        suburb\n        state\n        country\n        geolocation {\n          latitude\n          longitude\n        }\n      }\n    }\n  }\n}\n    "])));
/**
 * __useUpdateUserLocationMutation__
 *
 * To run a mutation, you first call `useUpdateUserLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLocationMutation, { data, loading, error }] = useUpdateUserLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */

export function useUpdateUserLocationMutation(baseOptions) {
  var options = _objectSpread(_objectSpread({}, defaultOptions), baseOptions);

  return Apollo.useMutation(UpdateUserLocationDocument, options);
}