import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3;

import { border, color, compose, css, display, focusBorderColorProp, layout, space, styled, system, themeGet, typography } from "@catchoftheday/theme";
var customStyles = compose(space, color, typography, border, display, layout);
var hoverColorTransform = system({
  hoverBorderColor: {
    property: "borderColor",
    scale: "colors"
  }
});
var activeTextColorTransform = system({
  activeTextColor: {
    property: "color",
    scale: "colors"
  }
});
export var Textarea = styled("textarea")(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  outline: 0;\n  transition: border-color ", ";\n  ", "\n  &:focus {\n    ", "\n  }\n\n  &:focus,\n  &:hover:not(:disabled) {\n    ", "\n  }\n\n  &:disabled {\n    cursor: not-allowed;\n    color: ", ";\n    background-color: ", ";\n  }\n\n  ::placeholder {\n    font-weight: ", ";\n    color: ", ";\n  }\n  ", ";\n\n  ", ";\n"])), themeGet("transitions.default"), customStyles, focusBorderColorProp, activeTextColorTransform, themeGet("colors.typography.text"), themeGet("colors.ui.backgroundColor"), themeGet("fontWeights.normal"), themeGet("colors.typography.inactive"), function (props) {
  return props.error && css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      border-color: ", ";\n      :not(:placeholder-shown) + label {\n        color: ", ";\n      }\n    "])), themeGet("colors.ui.error")(props), themeGet("colors.ui.error")(props));
}, function (props) {
  return !props.error && !props.disabled && css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      :focus {\n        ", "\n      }\n\n      :hover {\n        ", "\n      }\n\n      :placeholder-shown:hover + label {\n        color: ", ";\n      }\n\n      :focus + label {\n        color: ", ";\n      }\n    "])), focusBorderColorProp(props), hoverColorTransform(props), themeGet("colors.secondaryDark")(props), themeGet("colors.secondary")(props));
});
Textarea.displayName = "Textarea";
Textarea.defaultProps = {
  disabled: false,
  display: "inline",
  rows: 5,
  cols: 30,
  p: 5,
  bg: "white",
  width: "100%",
  color: "typography.text",
  fontSize: "base",
  lineHeight: "normal",
  border: 1,
  borderRadius: "default",
  borderColor: "ui.borderColor",
  textAlign: "left",
  hoverBorderColor: "secondaryDark",
  focusBorderColor: "secondary"
};
export default Textarea;