import React from "react";
import { Box, Flex, Span, Text } from "@catchoftheday/cg-components";
import { SOLD_OUT_MESSAGE } from "@product-app/consts";
import { NotifyMeButton } from "../NotifyMeButton";
import { WishlistButton } from "../WishlistButton";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SoldOut = function SoldOut(_ref) {
  var inWishlist = _ref.inWishlist,
      willNotify = _ref.willNotify,
      onToggleWishlist = _ref.onToggleWishlist,
      onNotify = _ref.onNotify;
  return ___EmotionJSX(Flex, {
    flexDirection: "column"
  }, ___EmotionJSX(Text, {
    fontWeight: "light",
    textAlign: "center",
    mt: "0"
  }, ___EmotionJSX(Span, {
    color: "error",
    fontWeight: "normal"
  }, "Out of stock."), " ", SOLD_OUT_MESSAGE), ___EmotionJSX(Flex, {
    flexDirection: "row"
  }, ___EmotionJSX(Box, {
    flex: "1 1 auto"
  }, ___EmotionJSX(NotifyMeButton, {
    willNotify: willNotify,
    sizeVariant: {
      xs: "md",
      lg: "xl"
    },
    width: "100%",
    onClick: onNotify
  })), ___EmotionJSX(Box, {
    flex: "0 1 auto"
  }, ___EmotionJSX(WishlistButton, {
    width: {
      xs: "57px",
      lg: "72px"
    },
    height: {
      xs: "57px",
      lg: "72px"
    },
    ml: "6px",
    onWishlistClick: onToggleWishlist,
    inWishlist: inWishlist
  }))));
};