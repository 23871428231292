import _toConsumableArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import React from "react";
import { Box, Flex, Heading, IconOnePassTags, Link, LinkButton, List, ListItem, Span } from "@catchoftheday/cg-components";
import { MetaTags } from "@catchoftheday/common-vendors";
import { centsToDollars, formatPrice, updateQueryParams } from "@catchoftheday/utilities";
import { CATCH_SELLER_ID } from "@product-app/consts";
import { jsx as ___EmotionJSX } from "@emotion/react";

var getUpdatedQueryParams = function getUpdatedQueryParams(offerId) {
  return updateQueryParams({
    queryParams: [{
      key: "offer_id",
      value: offerId
    }],
    returnUrl: true
  });
};

export var OfferSelector = function OfferSelector(_ref) {
  var offers = _ref.offers,
      _ref$selectedOfferId = _ref.selectedOfferId,
      selectedOfferId = _ref$selectedOfferId === void 0 ? "0" : _ref$selectedOfferId,
      selectOffer = _ref.selectOffer;
  var priceArray = offers.map(function (offer) {
    return offer.sellPrice.amount;
  });
  var highPrice = Math.max.apply(Math, _toConsumableArray(priceArray));
  var lowPrice = Math.min.apply(Math, _toConsumableArray(priceArray));
  return ___EmotionJSX(Box, {
    border: "solid 1px",
    borderColor: "ui.borderColor",
    mb: "16px",
    backgroundColor: "white",
    position: "relative"
  }, ___EmotionJSX(Heading.h5, {
    m: "16px"
  }, "More buying options"), ___EmotionJSX(List, {
    mb: "0",
    itemType: "http://schema.org/AggregateOffer",
    itemProp: "offers",
    itemScope: true
  }, offers.map(function (offer) {
    return ___EmotionJSX(ListItem, {
      key: offer.id,
      borderTop: "solid 1px",
      borderColor: "ui.borderColor",
      padding: "5px 16px 5px 0",
      display: offer.id === selectedOfferId ? "none" : "list-item",
      itemType: "http://schema.org/Offer",
      itemProp: "offers",
      itemScope: true
    }, ___EmotionJSX(Flex, {
      alignItems: "center"
    }, ___EmotionJSX(Box, {
      minWidth: "40px",
      textAlign: "center"
    }, offer.clubFreeShipping && ___EmotionJSX(IconOnePassTags, {
      width: "20px",
      title: "OnePass free shipping"
    })), ___EmotionJSX(Box, {
      flex: "1 1 auto",
      pr: "5px",
      lineHeight: "1"
    }, ___EmotionJSX(Span, {
      fontWeight: "normal"
    }, formatPrice(offer.sellPrice.amount), " "), ___EmotionJSX(Span, {
      fontWeight: "light",
      fontSize: "xs",
      itemType: "http://schema.org/Organization",
      itemProp: "seller",
      itemScope: true
    }, offer.freeShipping ? "Free delivery. " : "+ Shipping. ", "Sold by:", " ", offer.seller.id == CATCH_SELLER_ID ? ___EmotionJSX(Span, {
      fontWeight: "normal",
      fontSize: "xs",
      itemProp: "name"
    }, "Catch") : ___EmotionJSX(Link, {
      href: "/seller/".concat(offer.seller.slug, "?oid=").concat(offer.id, "&soid=").concat(selectedOfferId),
      color: "text",
      fontWeight: "normal",
      textDecoration: "underline",
      itemProp: "name"
    }, offer.seller.name))), ___EmotionJSX(LinkButton, {
      sizeVariant: "xs",
      variant: "secondary",
      outline: true,
      padding: "4px 24px",
      onClick: function onClick(e) {
        e.preventDefault();
        selectOffer(offer.id);
        updateQueryParams({
          queryParams: [{
            key: "offer_id",
            value: offer.id
          }]
        });
      },
      href: "".concat(location.pathname, "?").concat(getUpdatedQueryParams(offer.id)),
      width: "96px",
      itemProp: "url"
    }, "Select")), ___EmotionJSX(MetaTags, {
      tags: [{
        itemProp: "price",
        content: "".concat(centsToDollars(offer.sellPrice.amount))
      }, {
        itemProp: "priceCurrency",
        content: offer.sellPrice.currency
      }, {
        itemProp: "availability",
        content: "http://schema.org/InStock"
      }]
    }));
  })), ___EmotionJSX(MetaTags, {
    tags: [{
      itemProp: "highPrice",
      content: "".concat(centsToDollars(highPrice))
    }, {
      itemProp: "lowPrice",
      content: "".concat(centsToDollars(lowPrice))
    }, {
      itemProp: "offerCount",
      content: "".concat(offers.length)
    }, {
      itemProp: "priceCurrency",
      content: offers[0].sellPrice.currency
    }]
  }));
};