import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import React from "react";
import { Icon } from "../../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconZipPayGrey = function IconZipPayGrey(props) {
  return ___EmotionJSX(Icon, _extends({
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 762.97 279.33"
  }, props), ___EmotionJSX("path", {
    d: "M17.18 225.07l6.66 54.26H257l-7.63-62.14H140.66l-.95-7.7 100.13-69.97-6.69-54.37H0l7.63 62.14h108.89l.96 7.77-100.3 70.01z"
  }), ___EmotionJSX("path", {
    fill: "#c1c1c1",
    d: "M260.24 85.15l23.84 194.18h233.34L493.58 85.15H260.24z"
  }), ___EmotionJSX("path", {
    d: "M762.12 155.06c-5.38-43.64-39.66-70.09-86.26-69.9H520.65l23.84 194.18h69.82l-4.77-38.84h73.89c58.15-.01 84.76-36.24 78.69-85.44zm-86.24 31l-73 .08-5.72-46.6 73.41.06c17.26.21 26.09 9.93 27.52 23.23.91 8.56-3.04 23.22-22.21 23.22z"
  }), ___EmotionJSX("ellipse", {
    cx: "304.39",
    cy: "34.79",
    rx: "32.7",
    ry: "37.01",
    transform: "rotate(-46.77 304.405 34.778)"
  }));
};