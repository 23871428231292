import React, { useState } from "react";
import { Box, Button, Flex, Heading, IconWarranty, Modal, Text } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
var mapWarranty = {
  CATCH: "Catch",
  MANUFACTURER: "Manufacturer",
  DIRECT_IMPORT: "Direct import",
  NONE: undefined
};
export var WarrantyInfo = function WarrantyInfo(_ref) {
  var warranty = _ref.warranty;

  var _useState = useState(false),
      warrantyInfoOpen = _useState[0],
      setWarrantyInfoOpen = _useState[1];

  var warrantyType = mapWarranty[warranty.type];

  if (!warrantyType) {
    return null;
  }

  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Flex, null, ___EmotionJSX(Box, null, ___EmotionJSX(IconWarranty, {
    width: "30px",
    height: "30px"
  })), ___EmotionJSX(Box, {
    pl: "12px"
  }, ___EmotionJSX(Text, {
    fontWeight: "normal",
    m: 0,
    fontSize: "sm",
    lineHeight: "30px"
  }, "Warranty"), ___EmotionJSX(Text, {
    m: 0,
    mt: "-5px"
  }, warrantyType, " warranty.", " ", ___EmotionJSX(Button, {
    onClick: function onClick() {
      return setWarrantyInfoOpen(true);
    },
    variant: "plain",
    p: 0,
    m: 0,
    textTransform: "capitalize",
    color: "secondary",
    minWidth: "auto!important;",
    fontSize: "sm",
    fontWeight: "light"
  }, "Learn More")))), warranty.description && ___EmotionJSX(Modal, {
    isOpen: warrantyInfoOpen,
    onRequestClose: function onRequestClose() {
      return setWarrantyInfoOpen(false);
    }
  }, ___EmotionJSX(Heading.h3, null, "Warranty"), ___EmotionJSX(Box, {
    dangerouslySetInnerHTML: {
      __html: warranty.description
    }
  })));
};