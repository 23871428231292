import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["willNotify"];
import React from "react";
import { Button, Flex, IconBellRinging, Span } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var NotifyMeButton = function NotifyMeButton(_ref) {
  var willNotify = _ref.willNotify,
      buttonProps = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Button, _extends({
    variant: "tertiary",
    "aria-label": "Notify me",
    outline: willNotify
  }, buttonProps), ___EmotionJSX(Flex, {
    justifyContent: "center",
    alignItems: "center"
  }, ___EmotionJSX(Span, {
    fontWeight: "normal",
    fontSize: "inherit"
  }, !willNotify ? "Notify me" : "We'll Notify You"), willNotify && ___EmotionJSX(IconBellRinging, {
    ml: "0.25em",
    width: "1.25em",
    height: "1.25em"
  })));
};