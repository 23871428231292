import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["onSubmit", "onSubmitError", "schema", "children"];
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { jsx as ___EmotionJSX } from "@emotion/react";

/**
 * Handles setting up the react-hook-form context and connecting to the form component.
 */
export var Form = function Form(_ref) {
  var onSubmit = _ref.onSubmit,
      onSubmitError = _ref.onSubmitError,
      schema = _ref.schema,
      children = _ref.children,
      props = _objectWithoutProperties(_ref, _excluded);

  var methods = useForm({
    resolver: schema ? zodResolver(schema) : undefined
  });
  var handleSubmit = methods.handleSubmit,
      trigger = methods.trigger,
      getValues = methods.getValues;
  return ___EmotionJSX(FormProvider, methods, ___EmotionJSX("form", _extends({}, props, {
    onSubmit: handleSubmit(onSubmit || noop, onSubmitError || noop)
  }), typeof children === "function" ? children({
    trigger: trigger,
    getValues: getValues
  }) : children));
};
Form.displayName = "Form";
export default Form;

var noop = function noop() {
  return undefined;
};