import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconDeliveryTruck = createSvgIconComponent({
  paths: [{
    d: "M9.07125 11.6668H17.2858M5.65849 11.6668C8.00061 11.6668 4.8053 11.6668 4.8053 11.6668V1.42847H18.8829V9.96038",
    props: {
      strokeWidth: "1.5"
    }
  }, {
    d: "M18.8811 3.98859H21.7251L24.0002 8.25454V11.6673H20.1609",
    props: {
      strokeWidth: "1.5"
    }
  }, {
    d: "M3.52442 6.1214L0.964844 6.1214",
    props: {
      strokeWidth: "0.853191"
    }
  }, {
    d: "M3.5234 7.8277L1.81702 7.8277",
    props: {
      strokeWidth: "0.853191"
    }
  }, {
    d: "M3.52568 9.53363L2.67249 9.53363",
    props: {
      strokeWidth: "0.853191"
    }
  }],
  children: ___EmotionJSX(React.Fragment, null, ___EmotionJSX("circle", {
    cx: "8.36345",
    cy: "11.6665",
    r: "1.38298",
    strokeWidth: "1.5"
  }), ___EmotionJSX("circle", {
    cx: "19.3149",
    cy: "11.6666",
    r: "1.38298",
    strokeWidth: "1.5"
  })),
  viewBox: "0 0 25 14",
  fill: "none",
  stroke: "currentColor"
});