import _toConsumableArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import React from "react";
import { Breadcrumbs as BreadcrumbsComponent } from "@catchoftheday/cg-components";
import { useEventBreadcrumbQuery } from "@product-app/generated/graphql";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var Breadcrumbs = function Breadcrumbs(_ref) {
  var eventSlug = _ref.eventSlug,
      category = _ref.category;

  var _useEventBreadcrumbQu = useEventBreadcrumbQuery(eventSlug ? {
    variables: {
      slug: eventSlug
    }
  } : {
    skip: true
  }),
      data = _useEventBreadcrumbQu.data;

  var event = data === null || data === void 0 ? void 0 : data.eventBySlug.event;
  var breadcrumbs = [].concat(_toConsumableArray(generateBreadcrumbs(category)), _toConsumableArray(event ? [{
    label: event.title,
    href: event.url
  }] : []));

  var getBreadcrumbItemProps = function getBreadcrumbItemProps(index) {
    if (index === 0) {
      return {
        ml: {
          xs: 4,
          md: 5
        }
      };
    }

    return {};
  };

  return ___EmotionJSX(BreadcrumbsComponent, {
    breadcrumbs: breadcrumbs,
    containerProps: {
      pt: 4
    },
    listItemProps: getBreadcrumbItemProps
  });
};
export function generateBreadcrumbs(data) {
  function generate(category, breadcrumbs) {
    if (!category) return breadcrumbs;
    var crumbs = breadcrumbs.concat({
      label: category.name,
      href: "/category/".concat(category.slug.replace(/^\//, ""))
    });
    return generate(category.parent, crumbs);
  }

  return generate(data, []).reverse();
}