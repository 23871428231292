import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconDeliveryExpress = createSvgIconComponent({
  children: ___EmotionJSX(React.Fragment, null, ___EmotionJSX("g", {
    clipPath: "url(#a)"
  }, ___EmotionJSX("circle", {
    cx: "13.811",
    cy: "13.121",
    r: "7.609",
    transform: "rotate(-.537 13.811 13.12)",
    stroke: "currentColor",
    strokeWidth: "1.5"
  }), ___EmotionJSX("path", {
    fill: "none",
    d: "M12 4h4v2h-4zM11 3h6v1h-6zM6.4 7.625l2.62-2.171.996 1.203-2.62 2.17zM21.5 7.834l-2.35-2.46-1.101 1.05 2.35 2.461zM14 8l1 6c-.095 1.235-1.905 1.235-2 0l1-6Z"
  }), ___EmotionJSX("path", {
    d: "M7 17H3M6 14H2M7 11H0",
    stroke: "currentColor",
    strokeWidth: "1.5"
  })), ___EmotionJSX("defs", null, ___EmotionJSX("clipPath", {
    id: "a"
  }, ___EmotionJSX("path", {
    fill: "#fff",
    d: "M0 0h23v24H0z"
  })))),
  viewBox: "0 0 23 24",
  fill: "none",
  stroke: "currentColor"
});