import React from "react";
import Head from "next/head";
import { cleanHostName } from "@catchoftheday/utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SEO = function SEO(_ref) {
  var title = _ref.title,
      description = _ref.description,
      domain = _ref.domain,
      image = _ref.image,
      canonical = _ref.canonical,
      alternateTags = _ref.alternateTags;
  var prettyDomain = domain && cleanHostName(domain, true);
  return ___EmotionJSX(Head, null, description && ___EmotionJSX("meta", {
    name: "description",
    content: description
  }), ___EmotionJSX("title", null, title, prettyDomain ? " | ".concat(prettyDomain) : ""), ___EmotionJSX("meta", {
    property: "og:type",
    content: "website"
  }), ___EmotionJSX("meta", {
    property: "og:title",
    content: title
  }), (canonical === null || canonical === void 0 ? void 0 : canonical.url) && ___EmotionJSX("meta", {
    property: "og:url",
    content: canonical.url
  }), ___EmotionJSX("meta", {
    property: "og:image",
    content: image
  }), prettyDomain && ___EmotionJSX("meta", {
    property: "og:site_name",
    content: prettyDomain
  }), ___EmotionJSX("meta", {
    property: "og:description",
    content: title
  }), ___EmotionJSX("meta", {
    property: "og:locale",
    content: "en_AU"
  }), (canonical === null || canonical === void 0 ? void 0 : canonical.url) && ___EmotionJSX("link", {
    rel: "canonical",
    href: canonical.url
  }), alternateTags.map(function (data) {
    var _data$media, _data$locale;

    return ___EmotionJSX("link", {
      key: "".concat(data.url, ":").concat(data.media, ":").concat(data.locale),
      rel: "alternate",
      href: data.url,
      media: (_data$media = data.media) !== null && _data$media !== void 0 ? _data$media : undefined,
      hrefLang: (_data$locale = data.locale) !== null && _data$locale !== void 0 ? _data$locale : undefined
    });
  }));
};