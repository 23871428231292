import React from "react";
import { Flex, IconAfterPayBlack, IconLatitudePayGrey, IconPaypalGrey, IconZipPayGrey } from "@catchoftheday/cg-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PaymentOptions = function PaymentOptions(_ref) {
  var paymentOptions = _ref.paymentOptions;
  if (!paymentOptions) return null;
  return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Flex, {
    justifyContent: "center",
    alignItems: "center",
    px: {
      xs: 0,
      md: 4
    },
    m: "10px 0 20px 0"
  }, paymentOptions.includes("PAYPAL") && ___EmotionJSX(IconPaypalGrey, {
    mx: 2,
    "aria-label": "paypal logo",
    height: "16px",
    width: "auto"
  }), paymentOptions.includes("AFTERPAY") && ___EmotionJSX(IconAfterPayBlack, {
    mx: 2,
    "aria-label": "afterpay logo",
    height: "16px",
    width: "auto"
  }), paymentOptions.includes("ZIPPAY") && ___EmotionJSX(IconZipPayGrey, {
    style: {
      padding: 0
    },
    mx: 2,
    height: "16px",
    width: "auto"
  }), paymentOptions.includes("LATITUDEPAY") && ___EmotionJSX(IconLatitudePayGrey, {
    mx: 2,
    height: "16px",
    width: "auto"
  })));
};