import _defineProperty from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

import React, { useMemo } from "react";
import { Box, CustomSelect, Flex, Span } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { formatPrice } from "@catchoftheday/utilities";
import { NotifyMeButton } from "../NotifyMeButton";
import { jsx as ___EmotionJSX } from "@emotion/react";
var AttributeItem = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return props.enabled && "&:hover, &.selected {\n    background-color: ".concat(themeGet("colors.secondary")(props), ";\n    color: white;\n  }");
});
var AttributeSelectorWrapper = styled(Box)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  z-index: 2;\n"])));
export var AttributeSelector = function AttributeSelector(_ref) {
  var variants = _ref.variants,
      option = _ref.option,
      onNotify = _ref.onNotify,
      selectAttribute = _ref.selectAttribute,
      selectedOffer = _ref.selectedOffer;
  var mappedVariants = Object.assign.apply(Object, [{}].concat(_toConsumableArray(variants.map(function (variant) {
    var _variant$wishlist$wis;

    return _defineProperty({}, variant.variantOptionValues[0].id, {
      variantId: variant.id,
      sellable: variant.sellable,
      willNotify: (_variant$wishlist$wis = variant.wishlist.wishlistItem) === null || _variant$wishlist$wis === void 0 ? void 0 : _variant$wishlist$wis.notify,
      limitedQuantityRemaining: variant.limitedQuantityRemaining,
      price: variant.price
    });
  }))));
  var selectedOptionValueIndex = option.values.findIndex(function (value) {
    return mappedVariants[value.id].sellable && value.id === selectedOffer.variantOptionValueId;
  });
  var firstAvailableIndex = option.values.findIndex(function (value) {
    return mappedVariants[value.id].sellable;
  });
  var optionItems = useMemo(function () {
    return option.values.map(function (value, index) {
      var _mappedVariants$value = mappedVariants[value.id],
          sellable = _mappedVariants$value.sellable,
          variantId = _mappedVariants$value.variantId,
          willNotify = _mappedVariants$value.willNotify,
          limitedQuantityRemaining = _mappedVariants$value.limitedQuantityRemaining,
          price = _mappedVariants$value.price;
      var priceDifference;

      if (price && selectedOffer.price) {
        priceDifference = price - selectedOffer.price || undefined;
      }

      return {
        label: value.name,
        component: ___EmotionJSX(AttributeItem, {
          color: sellable ? "inherit" : "typography.inactive",
          height: "55px",
          alignItems: "center",
          justifyContent: "space-between",
          p: "0 20px",
          borderTop: index == 0 ? "none" : "solid 1px",
          borderColor: "ui.borderColor",
          enabled: sellable,
          cursor: sellable ? "pointer" : "not-allowed",
          className: index == selectedOptionValueIndex ? "selected" : ""
        }, ___EmotionJSX(Box, null, ___EmotionJSX(Span, {
          fontWeight: "normal",
          lineHeight: "0.75rem"
        }, value.name), !sellable && ___EmotionJSX(Span, {
          textTransform: "uppercase",
          fontSize: "xs",
          fontWeight: "normal",
          display: "block"
        }, "Out of stock"), sellable && !!limitedQuantityRemaining && ___EmotionJSX(Span, {
          fontSize: "xs",
          fontWeight: "normal",
          color: "tertiary",
          display: "block"
        }, "Only ", limitedQuantityRemaining, " left!")), !sellable && ___EmotionJSX(NotifyMeButton, {
          willNotify: willNotify,
          sizeVariant: "xs",
          minWidth: "153px",
          p: "4px 10px",
          onClick: function onClick(e) {
            e.stopPropagation();
            onNotify(variantId, !!willNotify);
          },
          key: variantId
        }), sellable && priceDifference && ___EmotionJSX(Span, {
          fontWeight: "normal",
          lineHeight: "0.75rem"
        }, priceDifference > 0 && "+", formatPrice(priceDifference))),
        onSelect: function onSelect() {
          return sellable && variantId !== selectedOffer.variantId ? selectAttribute(variantId) : undefined;
        },
        disabled: !sellable
      };
    });
  }, [mappedVariants, onNotify, option.values, selectAttribute, selectedOffer, selectedOptionValueIndex]);
  return ___EmotionJSX(AttributeSelectorWrapper, null, ___EmotionJSX(CustomSelect, {
    customSelectItems: optionItems,
    containerProps: {
      width: "100%",
      backgroundColor: "white"
    },
    fontSize: "sm",
    defaultSelectedIndex: selectedOptionValueIndex > -1 ? selectedOptionValueIndex : firstAvailableIndex > -1 ? firstAvailableIndex : undefined
  }, "Select ", option.name));
};