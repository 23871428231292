import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconCatchCLogo = createSvgIconComponent({
  viewBox: "0 0 195 150",
  children: ___EmotionJSX("image", {
    width: "195px",
    height: "150px",
    xlinkHref: "https://s.catch.com.au/static/catch/images/logo-condensed-b8974103f2.svg"
  })
});