import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React, { useEffect } from "react";
import { styled } from "@catchoftheday/theme";
import { Box } from "../Box";
import { Flex } from "../Flex";
import { ArrowRightIcon } from "../Icon";
import { Link } from "../Link";
import { List } from "../List";
import { ListItem } from "../ListItem";
import { Span } from "../Text";
import { jsx as ___EmotionJSX } from "@emotion/react";
var spanProps = {
  fontSize: "inherit",
  fontWeight: "inherit",
  lineHeight: "inherit"
};
var BreadcrumbContainer = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: block;\n  overflow: auto;\n  max-width: 100%;\n  white-space: nowrap;\n"])));
export var Breadcrumbs = function Breadcrumbs(_ref) {
  var breadcrumbs = _ref.breadcrumbs,
      containerProps = _ref.containerProps,
      listProps = _ref.listProps,
      listItemProps = _ref.listItemProps;
  var scrollRef = React.useRef(null); // The design requires the breadcrumb to scroll from the right on mobile

  useEffect(function () {
    var ref = scrollRef.current;
    if (!ref) return;
    var observer = new ResizeObserver(function () {
      ref.scrollLeft = ref.scrollWidth;
    });
    observer.observe(ref);
    return function () {
      return observer.unobserve(ref);
    };
  }, [breadcrumbs]);
  return ___EmotionJSX(BreadcrumbContainer, _extends({
    ref: scrollRef
  }, containerProps, {
    role: "navigation"
  }), ___EmotionJSX(List, _extends({
    m: "0",
    p: "0",
    mb: 2,
    itemType: "http://schema.org/BreadcrumbList",
    itemScope: true
  }, listProps), breadcrumbs.map(function (_ref2, index) {
    var label = _ref2.label,
        href = _ref2.href,
        leaf = _ref2.leaf;
    return ___EmotionJSX(ListItem, _extends({
      fontSize: "sm",
      key: label,
      itemType: "http://schema.org/ListItem",
      itemScope: true,
      itemProp: "itemListElement",
      display: "inline-block",
      mr: index === breadcrumbs.length - 1 ? 15 : 0
    }, listItemProps ? listItemProps(index) : {}), ___EmotionJSX(Flex, {
      alignItems: "center"
    }, leaf ? ___EmotionJSX(Span, _extends({
      itemProp: "name",
      color: "typography.inactive"
    }, spanProps), label) : ___EmotionJSX(Link, {
      href: href,
      itemProp: "item",
      hoverColor: "secondary"
    }, ___EmotionJSX(Span, _extends({
      itemProp: "name"
    }, spanProps), label)), index !== breadcrumbs.length - 1 ? ___EmotionJSX(Flex, {
      mx: 2
    }, ___EmotionJSX(ArrowRightIcon, null)) : null), ___EmotionJSX("meta", {
      itemProp: "position",
      content: "".concat(index + 1)
    }));
  })));
};
Breadcrumbs.displayName = "Breadcrumbs";
export default Breadcrumbs;