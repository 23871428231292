import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { Box } from "@catchoftheday/cg-components";
import { rem, styled } from "@catchoftheday/theme";
import { Specifications } from "./Specifications";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductDescription = function ProductDescription(_ref) {
  var description = _ref.description,
      footer = _ref.footer,
      retailPriceDisclaimer = _ref.retailPriceDisclaimer,
      specifications = _ref.specifications;
  return ___EmotionJSX(DescriptionContainer, null, description && ___EmotionJSX(Box, {
    dangerouslySetInnerHTML: {
      __html: description
    },
    itemProp: "description"
  }), specifications && ___EmotionJSX(Specifications, {
    specifications: specifications
  }), footer ? ___EmotionJSX(Box, {
    className: "footer-text",
    dangerouslySetInnerHTML: {
      __html: footer
    }
  }) : null, retailPriceDisclaimer && ___EmotionJSX(Box, {
    fontSize: "sm",
    fontWeight: "normal",
    dangerouslySetInnerHTML: {
      __html: retailPriceDisclaimer
    }
  }));
};
/**
 * Styles migrated from cotd-website
 */

var DescriptionContainer = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  font-size: ", ";\n  font-family: Biennale, Helvetica, Arial, sans-serif;\n  font-weight: 100;\n  line-height: 1.4;\n\n  ol li,\n  ul li {\n    margin-bottom: 5px;\n  }\n\n  em {\n    font-style: normal;\n  }\n\n  p[class*=\"subtitle\"],\n  p[class^=\"subtitle\"] {\n    display: list-item;\n    list-style-type: disc;\n    margin-left: 40px;\n  }\n\n  .justify {\n    text-align: justify;\n  }\n\n  .footer-text {\n    font-weight: 400;\n\n    &--srp {\n      p {\n        display: inline;\n      }\n    }\n  }\n\n  img {\n    display: block;\n    max-width: 100%;\n    height: auto;\n  }\n"])), rem("14px"));