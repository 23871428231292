import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { Box, CalloutTag, Link } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var PromotionLinkComponent = styled(Link)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: inline-block;\n  flex: 1 1 auto;\n"])));
export var PromotionLink = function PromotionLink(_ref) {
  var promotions = _ref.promotions,
      eventId = _ref.eventId,
      useTag = _ref.useTag;
  var promotion = promotions.find(function (p) {
    return p.id === eventId;
  });

  var _ref2 = promotion || promotions[0],
      title = _ref2.title,
      url = _ref2.url;

  var props = {
    id: "multibuy-link",
    mr: 4,
    mb: {
      xs: 4,
      lg: 0
    }
  };
  return useTag ? ___EmotionJSX(Box, {
    flex: "1"
  }, ___EmotionJSX(CalloutTag, _extends({}, props, {
    url: url,
    fontSize: "base"
  }), title)) : ___EmotionJSX(PromotionLinkComponent, _extends({}, props, {
    href: url,
    hoverColor: "secondary"
  }), title);
};