import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React, { useState } from "react";
import { Flex } from "@catchoftheday/cg-components";
import { DeliveryEstimateError, DeliveryEstimateWidget } from "@catchoftheday/fulfilment/lib/delivery";
import { UserLocationWidget } from "@catchoftheday/fulfilment/lib/user-location";
import { styled, themeGet } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var DeliveryEstimateRoot = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  flex-direction: column;\n  gap: ", ";\n"])), themeGet("space.6"));
export function DeliveryEstimate(_ref) {
  var offerId = _ref.offerId;

  var _useState = useState(""),
      postcode = _useState[0],
      setPostcode = _useState[1];

  var _useState2 = useState(false),
      isError = _useState2[0],
      setIsError = _useState2[1];

  var _useState3 = useState(false),
      isLoading = _useState3[0],
      setIsLoading = _useState3[1];

  var handleLocationSelected = function handleLocationSelected(locationId, location) {
    var _location$postcode;

    if (locationId && !location.postcode) {
      console.warn("DeliveryEstimate: locationId is set but location is not");
      return;
    }

    setPostcode((_location$postcode = location === null || location === void 0 ? void 0 : location.postcode) !== null && _location$postcode !== void 0 ? _location$postcode : "");
  };

  var showDeliveryEstimate = offerId && postcode;
  return ___EmotionJSX(DeliveryEstimateRoot, null, ___EmotionJSX(UserLocationWidget, {
    title: "When will I receive this item?",
    onLocationSelected: handleLocationSelected,
    onLocationUpdateLoading: function onLocationUpdateLoading() {
      setIsLoading(true);
      setIsError(false);
    },
    onLocationUpdateCompleted: function onLocationUpdateCompleted() {
      return setIsLoading(false);
    },
    onLocationUpdateError: function onLocationUpdateError() {
      setIsLoading(false);
      setIsError(true);
    }
  }), isError && ___EmotionJSX(DeliveryEstimateError, null), showDeliveryEstimate && !isError && ___EmotionJSX(DeliveryEstimateWidget, {
    offerId: offerId,
    postcode: postcode,
    externalLoading: isLoading
  }));
}