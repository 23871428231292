import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["charts"];

var _templateObject;

import React, { useContext, useEffect } from "react";
import { Box, IconRuler, TextButton } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { SizeChartsContext } from "./SizeChartContext";
import { jsx as ___EmotionJSX } from "@emotion/react";
var SizeChartContainer = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  white-space: nowrap;\n"])));
export var SizeChartsButton = function SizeChartsButton(_ref) {
  var charts = _ref.charts,
      props = _objectWithoutProperties(_ref, _excluded);

  var _useContext = useContext(SizeChartsContext),
      setSizeCharts = _useContext.setSizeCharts,
      setSizeChartOpen = _useContext.setSizeChartOpen;

  useEffect(function () {
    setSizeCharts(charts.map(function (chart) {
      return chart.url;
    }));
  }, [charts, setSizeCharts]);
  return ___EmotionJSX(SizeChartContainer, props, ___EmotionJSX(TextButton, {
    onClick: function onClick() {
      return setSizeChartOpen(true);
    },
    fontSize: "sm",
    fontWeight: "light",
    whiteSpace: "nowrap"
  }, "Size Chart", ___EmotionJSX(IconRuler, {
    width: "19px",
    height: "12px",
    ml: "5px",
    title: "Size chart"
  })));
};