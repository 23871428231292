import _extends from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["title", "titleElement", "averageReview", "brand", "reviewCount", "buybox", "align"];
import React from "react";
import { Box, Flex, Heading, Link, StarRating } from "@catchoftheday/cg-components";
import { MetaTags } from "@catchoftheday/common-vendors";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductHeader = function ProductHeader(_ref) {
  var title = _ref.title,
      _ref$titleElement = _ref.titleElement,
      titleElement = _ref$titleElement === void 0 ? "h1" : _ref$titleElement,
      averageReview = _ref.averageReview,
      brand = _ref.brand,
      reviewCount = _ref.reviewCount,
      buybox = _ref.buybox,
      _ref$align = _ref.align,
      align = _ref$align === void 0 ? "left" : _ref$align,
      boxProps = _objectWithoutProperties(_ref, _excluded);

  var Title = Heading[titleElement];
  return ___EmotionJSX(Box, _extends({
    as: "header"
  }, boxProps), ___EmotionJSX(Title, {
    "data-testid": titleElement == "h1" ? "product-title" : "buybox-title",
    margin: 0,
    textAlign: align,
    itemProp: "name"
  }, title), ___EmotionJSX(Flex, {
    alignItems: "flex-end",
    justifyContent: align === "center" ? "center" : align === "right" ? "flex-end" : "flex-start"
  }, brand ? ___EmotionJSX(Link, {
    href: brand.href,
    fontSize: "0.875rem",
    color: "textTertiary",
    hoverColor: "textTertiary",
    textDecoration: "underline",
    itemProp: "brand",
    itemType: "https://schema.org/Brand",
    itemScope: true
  }, brand === null || brand === void 0 ? void 0 : brand.name, ___EmotionJSX(MetaTags, {
    tags: [{
      itemProp: "name",
      content: brand === null || brand === void 0 ? void 0 : brand.name
    }]
  })) : null, averageReview ? ___EmotionJSX(Box, {
    pl: 4,
    pr: 2,
    itemType: "http://schema.org/AggregateRating",
    itemProp: "aggregateRating",
    itemScope: true
  }, ___EmotionJSX(StarRating, {
    rating: averageReview,
    backgroundColour: buybox ? "#FFF" : "#FAFAFA"
  }), ___EmotionJSX(MetaTags, {
    tags: [{
      itemProp: "ratingValue",
      content: "".concat(averageReview)
    }, {
      itemProp: "reviewCount",
      content: "".concat(reviewCount)
    }]
  })) : null, reviewCount ? ___EmotionJSX(Link, {
    href: "#reviews",
    fontSize: "0.875rem"
  }, "(", reviewCount, " Review", reviewCount === 1 ? "" : "s", ")") : null));
};