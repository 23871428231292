import _slicedToArray from "/root/code/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";

/**
 * Location Suggestions Dropdown Hook
 * Consolidates related logic for the location suggestions dropdown.
 */
import { useState } from "react";
import { useDebouncedState } from "@react-hookz/web"; // TODO: Figure out why the `paths` `@/*` alias in tsconfig.json doesn't work

import { useLocationSuggestionsQuery } from "../../generated/graphql.jsx";
import { getDropdownData } from "./functions/getDropdownData.jsx";
var MIN_SEARCH_TERM_LENGTH = 3;
export function useLocationSuggestionsDropdown(props) {
  var _ref = props || {},
      _ref$initialLocation = _ref.initialLocation,
      initialLocation = _ref$initialLocation === void 0 ? "" : _ref$initialLocation;

  var _useState = useState(initialLocation),
      selectedLocation = _useState[0],
      setSelectedLocation = _useState[1];

  var _useDebouncedState = useDebouncedState("", 150, 500),
      _useDebouncedState2 = _slicedToArray(_useDebouncedState, 2),
      searchTerm = _useDebouncedState2[0],
      setSearchTerm = _useDebouncedState2[1];

  var _useLocationSuggestio = useLocationSuggestionsQuery({
    variables: {
      searchTerm: searchTerm
    },
    skip: searchTerm.length < MIN_SEARCH_TERM_LENGTH
  }),
      queryData = _useLocationSuggestio.data,
      error = _useLocationSuggestio.error;

  var locations = queryData === null || queryData === void 0 ? void 0 : queryData.locationSuggestions.postcodes;
  var data = getDropdownData({
    searchTermLength: searchTerm.length,
    selectedLocation: selectedLocation,
    locations: locations,
    error: error
  });
  return {
    searchTerm: searchTerm,
    selectedLocation: selectedLocation,
    setSearchTerm: setSearchTerm,
    setSelectedLocation: setSelectedLocation,
    data: data,
    locations: locations
  };
}