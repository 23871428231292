import React from "react";
import { Heading, InputDropdown } from "@catchoftheday/cg-components";
import { useLocationSuggestionsDropdown } from "./useLocationSuggestionsDropdown.js";
import { jsx as ___EmotionJSX } from "@emotion/react";
export function LocationWidget(_ref) {
  var title = _ref.title,
      label = _ref.label,
      initialLocation = _ref.initialLocation,
      onLocationSelected = _ref.onLocationSelected;
  var titleElement = typeof title === "string" ? ___EmotionJSX(Heading.h5, {
    mt: 0,
    mb: 3
  }, title) : ___EmotionJSX(React.Fragment, null, title ? ___EmotionJSX("div", null, title) : null);

  var _useLocationSuggestio = useLocationSuggestionsDropdown({
    initialLocation: initialLocation
  }),
      data = _useLocationSuggestio.data,
      setSearchTerm = _useLocationSuggestio.setSearchTerm,
      setSelectedLocation = _useLocationSuggestio.setSelectedLocation,
      locations = _useLocationSuggestio.locations;

  return ___EmotionJSX("div", null, titleElement, ___EmotionJSX(InputDropdown, {
    data: data,
    onInputChange: function onInputChange(value, reason) {
      if (reason.onChange) setSearchTerm(value);
    },
    onItemSelected: function onItemSelected(item) {
      var _item$value, _item$title;

      var locationId = item === null || item === void 0 ? void 0 : (_item$value = item.value) === null || _item$value === void 0 ? void 0 : _item$value.locationId;
      if (!locationId) return;
      setSelectedLocation((_item$title = item === null || item === void 0 ? void 0 : item.title) !== null && _item$title !== void 0 ? _item$title : "");
      var location = locations === null || locations === void 0 ? void 0 : locations.find(function (l) {
        return l.id === locationId;
      });

      if (!location) {
        console.error("LocationWidget: Location not found in list of locations.");
        return;
      }

      onLocationSelected === null || onLocationSelected === void 0 ? void 0 : onLocationSelected(locationId, location);
    },
    inputProps: {
      floatingLabelText: label !== null && label !== void 0 ? label : "Postcode or suburb",
      hoverBorderColor: "secondary",
      p: 4
    },
    defaultValue: initialLocation,
    clearInputOnFocus: true,
    autoHighlightFirstItem: true
  }));
}