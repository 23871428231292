import React from "react";
import { useFormState } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Text } from "../Text";
import { jsx as ___EmotionJSX } from "@emotion/react";

var defaultRender = function defaultRender(_ref) {
  var message = _ref.message;
  return ___EmotionJSX(Text, {
    color: "error",
    mt: "6px"
  }, message);
};

export var FormError = function FormError(props) {
  var _useFormState = useFormState(),
      errors = _useFormState.errors;

  return ___EmotionJSX(ErrorMessage, {
    name: props.name,
    errors: errors,
    render: props.children || defaultRender
  });
};
FormError.displayName = "FormError";
export default FormError;