import { createSvgIconComponent } from "../utils";
export var IconRuler = createSvgIconComponent({
  paths: [{
    d: "M18.5 9.6c0 .6-.4 1-1 1h-16c-.6 0-1-.4-1-1v-8c0-.6.4-1 1-1h16c.6 0 1 .4 1 1v8z",
    props: {
      strokeMiterlimit: "10",
      fill: "none"
    }
  }, {
    d: "M3.5 1.1v3M6.5 1.1v4M12.5 1.1v4M9.5 1.1v3M15.5 1.1v3",
    props: {
      strokeMiterlimit: "10",
      strokeLinecap: "round",
      fill: "none"
    }
  }],
  viewBox: "0 0 19 11.3",
  stroke: "currentColor"
});